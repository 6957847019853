import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApiURL from "../../controllers/Api";
import logo from "../../images/logo.png";

const OrderSummary = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [filterVisible, setFilterVisible] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [customerNameFilter, setCustomerNameFilter] = useState("");
  const filterPanelRef = useRef(null);

  // Close filter if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterPanelRef.current && !filterPanelRef.current.contains(event.target)) {
        setFilterVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchOrderDetails = async () => {
    try {
      const response = await fetch(`${ApiURL}/getReportData.php`);
      const data = await response.json();

      if (data?.success) {
        const orderData = data?.data || {}; // Assuming `data.data` contains the orders

        let updated = false;
        console.log(orderData);
        // Check if the orders data has changed and update the state only if there’s a change
        if (JSON.stringify(orderData.orders) !== JSON.stringify(orders)) {
          setOrders(orderData?.recent_orders || []);
          updated = true;
        }

        if (updated) {
          setLoading(false); // Stop loading state once data is fetched
        }

      } else {
        setError(data?.message || "Failed to fetch order details.");
        setLoading(false); // Stop loading even if there's an error
      }
    } catch (error) {
      setError("An error occurred while fetching order details. Please try again.");
      setLoading(false); // Stop loading on error
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const generateCSV = () => {
    const headers = [
      "SNo", "Order ID", "Customer Name", "Order Date", "Status", 
      "Total Quantity", "Total Price", "Total IGST", "Total SGST", "Total CGST", 
      "Grand Amount", "Rounding Difference", "Payable Amount"
    ];

    const rows = orders.map((order, orderIndex) => {
      const products = order.products
        ? order.products.split(";").map((prod) => {
            const [
              productId,
              productName,
              description,
              imgPath,
              quantity,
              price,
              size,
              totalAmount,
              igst,
              sgst,
              cgst,
              grandTotal,
              roundedAmount,
              roundingDifference
            ] = prod.split("|");

            return {
              productId,
              productName,
              description,
              imgPath,
              quantity,
              price,
              size,
              totalAmount,
              igst,
              sgst,
              cgst,
              grandTotal,
              roundedAmount,
              roundingDifference
            };
          })
        : [];

      // Calculate the total grand total, quantity, and IGST/SGST/CGST for the order
      const orderGrandTotal = products.reduce(
        (sum, product) => sum + parseFloat(product.grandTotal),
        0
      );

      const totalQuantity = products.reduce(
        (sum, product) => sum + parseInt(product.quantity),
        0
      );

      const totalPrice = products.reduce(
        (sum, product) => sum + parseFloat(product.price) * parseInt(product.quantity),
        0
      );

      const totalIGST = products.reduce(
        (sum, product) => sum + parseFloat(product.igst),
        0
      );

      const totalSGST = products.reduce(
        (sum, product) => sum + parseFloat(product.sgst),
        0
      );

      const totalCGST = products.reduce(
        (sum, product) => sum + parseFloat(product.cgst),
        0
      );

      const roundedGrandTotal = Math.round(orderGrandTotal);
      const roundingDifference = (orderGrandTotal - roundedGrandTotal) * -1;
      const orderRoundedAmount = roundedGrandTotal;

      // Only include one row per order with aggregated data
      const row = [
        orderIndex + 1,
        order.order_id,
        order.full_name,
        new Date(order.order_date).toLocaleDateString(),
        order.status,
        totalQuantity,
        totalPrice.toFixed(2),
        totalIGST.toFixed(2),
        totalSGST.toFixed(2),
        totalCGST.toFixed(2),
        `₹${orderGrandTotal.toFixed(2)}`,
        `₹${roundingDifference.toFixed(2)}`,
        `₹${orderRoundedAmount.toFixed(2)}`
      ];

      return row.join(",");
    });

    const csvContent = "data:text/csv;charset=utf-8,"
      + headers.join(",") + "\n"
      + rows.join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "order_summary.csv");
    document.body.appendChild(link);
    link.click();
  };

  const applyFilters = () => {
    let filteredOrders = orders;
    
    // Apply filters
    if (statusFilter) {
      filteredOrders = filteredOrders.filter(order => order.status.toLowerCase() === statusFilter.toLowerCase());
    }
    
    if (dateFrom) {
      filteredOrders = filteredOrders.filter(order => new Date(order.order_date) >= new Date(dateFrom));
    }

    if (dateTo) {
      filteredOrders = filteredOrders.filter(order => new Date(order.order_date) <= new Date(dateTo));
    }

    if (customerNameFilter) {
      filteredOrders = filteredOrders.filter(order => order.full_name.toLowerCase().includes(customerNameFilter.toLowerCase()));
    }

    setOrders(filteredOrders);
  };

  const resetFilters = () => {
    setStatusFilter("");
    setDateFrom("");
    setDateTo("");
    setCustomerNameFilter("");
    fetchOrderDetails(); // Re-fetch all orders
  };

  // Show loading message if the data is still being fetched
  if (loading) {
    return <p>Loading orders...</p>;
  }

  // Show error message if there's an error fetching data
  if (error) {
    return <p className="text-red-500">Error: {error}</p>;
  }

  // Show message if no orders are available
  if (!orders || orders.length === 0) {
    return <p>No orders available.</p>;
  }

  return (
    <div className="mx-auto bg-white shadow-lg rounded-lg p-6 my-10 border-t">
      <div id="receipt-content">
        <div className="text-center">
          <h1 className="w-28 mx-auto mb-4">
            <img src={logo} alt="Bhatia Emporium logo" />
          </h1>
          <p className="text-gray-600 text-lg font-semibold">Bhatia Emporium Receipt</p>
        </div>

        {/* Filter Dropdown */}
        <div className="relative inline-block float-right py-2 ">
          <button
            onClick={() => setFilterVisible(!filterVisible)}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md"
          >
            Filter Orders
          </button>

          {/* Dropdown Menu */}
          {filterVisible && (
            <div
              ref={filterPanelRef}
              className="absolute right-0 mt-5 w-56 bg-white border border-gray-300 rounded-md shadow-lg z-50"
            >
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-4">Filter Orders</h3>

                {/* Status Filter */}
                <div className="mb-4">
                  <label className="block text-sm font-semibold">Order Status</label>
                  <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option value="">All</option>
                    <option value="completed">Completed</option>
                    <option value="pending">Pending</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>

                {/* Date Range Filters */}
                <div className="mb-4">
                  <label className="block text-sm font-semibold">Date From</label>
                  <input
                    type="date"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-semibold">Date To</label>
                  <input
                    type="date"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Customer Name Filter */}
                <div className="mb-4">
                  <label className="block text-sm font-semibold">Customer Name</label>
                  <input
                    type="text"
                    value={customerNameFilter}
                    onChange={(e) => setCustomerNameFilter(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Search by name"
                  />
                </div>

                {/* Apply and Reset Buttons */}
                <div className="flex justify-between">
                  <button
                    onClick={resetFilters}
                    className="bg-gray-400 text-white px-4 py-2 rounded-md"
                  >
                    Reset
                  </button>
                  <button
                    onClick={applyFilters}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <button 
          onClick={generateCSV} 
          className="mt-4 bg-blue-500 text-white py-2 px-2 rounded mb-6">
          Export to CSV
        </button>

        {/* Orders Table */}
        <div className="mt-6">
          <h2 className="text-lg font-semibold text-gray-800">Order Details</h2>

          {/* Adding responsive container with scrollable table */}
          <div className="overflow-x-auto max-w-full relative">
            <table className="w-full mt-4 border border-gray-300 text-sm">
              <thead>
                <tr className="bg-[#dcbbfe] text-white">
                  <th className="border border-gray-300 p-1 text-left">SNo</th>
                  <th className="border border-gray-300 p-1 text-left">Order ID</th>
                  <th className="border border-gray-300 p-1 text-left">Customer Name</th>
                  <th className="border border-gray-300 p-1 text-left">Order Date</th>
                  <th className="border border-gray-300 p-1 text-left">Status</th>
                  <th className="border border-gray-300 p-1 text-right">Total Quantity</th>
                  <th className="border border-gray-300 p-1 text-right">Total Price</th>
                  <th className="border border-gray-300 p-1 text-right">Total IGST</th>
                  <th className="border border-gray-300 p-1 text-right">Total SGST</th>
                  <th className="border border-gray-300 p-1 text-right">Total CGST</th>
                  <th className="border border-gray-300 p-1 text-right w-32 font-bold">Grand Amount</th>
                  <th className="border border-gray-300 p-1 text-right w-32 font-bold">Rounding Difference</th>
                  <th className="border border-gray-300 p-1 text-right w-32 font-bold">Payable Amount</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order, orderIndex) => {
                  const products = order.products
                    ? order.products.split(";").map((prod) => {
                        const [
                          productId,
                          productName,
                          description,
                          imgPath,
                          quantity,
                          price,
                          size,
                          totalAmount,
                          igst,
                          sgst,
                          cgst,
                          grandTotal,
                          roundedAmount,
                          roundingDifference
                        ] = prod.split("|");

                        return {
                          productId,
                          productName,
                          description,
                          imgPath,
                          quantity,
                          price,
                          size,
                          totalAmount,
                          igst,
                          sgst,
                          cgst,
                          grandTotal,
                          roundedAmount,
                          roundingDifference
                        };
                      })
                    : [];

                  // Calculate the total grand total, quantity, and IGST/SGST/CGST for the order
                  const orderGrandTotal = products.reduce(
                    (sum, product) => sum + parseFloat(product.grandTotal),
                    0
                  );

                  const totalQuantity = products.reduce(
                    (sum, product) => sum + parseInt(product.quantity),
                    0
                  );

                  const totalPrice = products.reduce(
                    (sum, product) => sum + parseFloat(product.price) * parseInt(product.quantity),
                    0
                  );

                  const totalIGST = products.reduce(
                    (sum, product) => sum + parseFloat(product.igst),
                    0
                  );

                  const totalSGST = products.reduce(
                    (sum, product) => sum + parseFloat(product.sgst),
                    0
                  );

                  const totalCGST = products.reduce(
                    (sum, product) => sum + parseFloat(product.cgst),
                    0
                  );

                  const roundedGrandTotal = Math.round(orderGrandTotal);
                  const roundingDifference = (orderGrandTotal - roundedGrandTotal) * -1;
                  const orderRoundedAmount = roundedGrandTotal;

                  return products.map((product, productIndex) => (
                    <tr key={`${orderIndex}-${productIndex}`} className={`${(productIndex + orderIndex) % 2 === 0 ? "bg-gray-100" : "bg-white"} hover:bg-gray-200`}>
                      {productIndex === 0 && (
                        <>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text-left">{orderIndex+1}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text">{order.order_id}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1">{order.full_name}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1">{new Date(order.order_date).toLocaleDateString()}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1">{order.status}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text-right">{totalQuantity}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text-right">{totalPrice.toFixed(2)}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text-right">{totalIGST.toFixed(2)}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text-right">{totalSGST.toFixed(2)}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text-right">{totalCGST.toFixed(2)}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text-right">₹{orderGrandTotal.toFixed(2)}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text-right">₹{roundingDifference.toFixed(2)}</td>
                          <td rowSpan={products.length} className="border border-gray-300 p-1 text-right">₹{orderRoundedAmount.toFixed(2)}</td>
                        </>
                      )}
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
