import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiURl from "../controllers/Api";
import EmptyCart from "./EmptyCart";

const OrderSection = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0); // Star rating state
  const [cancelledOrderId, setCancelledOrderId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    if (!storedUserId) {
      setError("User not logged in or user_id not found in localStorage");
      setLoading(false);
      return;
    }
    setUserId(storedUserId);
  }, []);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const fetchOrders = async () => {
      let isMounted = true;
      try {
        setLoading(true);
        const response = await fetch(
          `${ApiURl}/getOrder.php?user_id=${userId}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.success) {
          if (isMounted) setOrders(data.data);
        } else {
          throw new Error(data.message || "Failed to fetch orders");
        }
      } catch (err) {
        if (isMounted) setError(err.message);
      } finally {
        if (isMounted) setLoading(false);
      }

      return () => {
        isMounted = false; // Cleanup function to avoid setting state on unmounted components
      };
    };

    fetchOrders();
  }, [userId]);

  const cancelOrder = async (orderId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to cancel this order?"
    );

    if (!isConfirmed) return; // If user cancels, don't proceed

    try {
      const response = await fetch(`${ApiURl}/updateOrderStatus.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order_id: orderId, status: "cancelled" }), // Set the status to "cancelled"
      });

      const result = await response.json();

      if (result?.success) {
        // Update the orders state to reflect the canceled status
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === orderId ? { ...order, status: "cancelled" } : order
          )
        );
        alert("Order has been cancelled!");

        // Show review popup after cancellation
        setCancelledOrderId(orderId);
        setShowReviewPopup(true);
      } else {
        alert(result?.message || "Failed to cancel order.");
      }
    } catch (error) {
      alert("An error occurred while cancelling the order.");
    }
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();

    // Check if rating is provided
    if (rating === 0) {
      alert("Please provide a star rating.");
      return;
    }

    // Prepare review data
    const reviewData = {
      order_id: cancelledOrderId,
      user_id: userId,
      review,
      rating,
    };

    try {
      // Send the review data to the PHP backend
      const response = await fetch(`${ApiURl}/submitReview.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reviewData),
      });

      // Check if the response is OK (status 200) and process the JSON response
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result?.success) {
        alert("Thank you for your feedback!");
        setShowReviewPopup(false); // Close the popup after successful submission
        setReview(""); // Reset review input
        setRating(0); // Reset rating to default (0)
      } else {
        alert(result?.message || "Failed to submit review.");
      }
    } catch (error) {
      // Catch and display any errors (including network and CORS issues)
      alert("An error occurred while submitting the review. Please try again later.");
      console.error(error); // Log the actual error to the console for debugging
    }
  };

  const handleClosePopup = () => {
    setShowReviewPopup(false); // Close the popup
  };

  const handleStarClick = (index) => {
    setRating(index + 1); // Set the rating when a star is clicked
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p className="text-red-500">
      <EmptyCart />
    </p>;
  }

  return (
    <div>
      {/* Order Section */}
      <div className="bg-white p-4 rounded-lg shadow-lg mt-4 mx-2 sm:mx-4 md:mx-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl md:text-2xl font-semibold">My Orders</h2>
        </div>

        <div className="space-y-4">
          {orders.length > 0 ? (
            orders.map((order) => (
              <div
                key={order.order_id}
                className="bg-gray-100 p-3 rounded-lg shadow-md"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6">
                  <div>
                    <h4 className="font-semibold text-gray-700">
                      Order #{order.order_id}
                    </h4>
                    <p className="text-gray-600 text-sm">Date: {order.date}</p>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-700">Status</h4>
                    <p className="text-gray-600 text-sm">{order.status}</p>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-700">Products</h4>
                    <p className="text-gray-600 text-sm">Products</p>
                  </div>
                  <div className="flex flex-col sm:flex-row gap-2 justify-center items-center sm:w-auto">
                    <button
                      onClick={() => navigate(`/ViewDetails/${order.order_id}`)}
                      className="bg-blue-500 text-white text-sm px-4 py-2 rounded-md hover:bg-blue-600 w-full sm:w-auto"
                    >
                      View Details
                    </button>
                    <button
                      onClick={() => navigate(`/Receipt/${order.order_id}`)}
                      className="bg-blue-700 text-white text-sm px-4 py-2 rounded-md hover:bg-blue-600 w-full sm:w-auto"
                    >
                      MY BILL
                    </button>

                    {/* Cancel Button */}
                    {order.status === "pending" && (
                      <button
                        onClick={() => cancelOrder(order.order_id)}
                        className="bg-red-500 text-white text-sm px-4 py-2 rounded-md hover:bg-red-600 w-full sm:w-auto"
                      >
                        Cancel Order
                      </button>
                    )}
                    {order.status === "completed" && (
                      <button
                        className="bg-gray-400 text-gray-800 text-sm px-4 py-2 rounded-md cursor-not-allowed w-full sm:w-auto"
                        disabled
                      >
                        Order Completed
                      </button>
                    )}
                    {order.status === "cancelled" && (
                      <button
                        className="bg-gray-400 text-gray-800 text-sm px-4 py-2 rounded-md cursor-not-allowed w-full sm:w-auto"
                        disabled
                      >
                        Order Cancelled
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No recent orders found.</p>
          )}
        </div>
      </div>

      {/* Review Popup */}
      {showReviewPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg w-80 sm:w-96 shadow-lg relative">
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 text-xl font-bold text-gray-600"
            >
              &times;
            </button>
            <div className="text-center">
              {/* Sad Emoji with animation */}
              <div className="text-5xl emoji-animation">😞</div>
              <h3 className="text-xl font-semibold mb-4">We're sorry to see you cancel your order!</h3>
              <p className="text-gray-700 mb-4">Please let us know why:</p>
              {/* Star Rating */}
              <div className="mb-4">
                {[...Array(5)].map((_, index) => (
                  <span
                    key={index}
                    className={`text-3xl cursor-pointer ${rating > index ? "text-yellow-500" : "text-gray-400"}`}
                    onClick={() => handleStarClick(index)}
                  >
                    ★
                  </span>
                ))}
              </div>
              <form onSubmit={handleReviewSubmit}>
                <textarea
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                  placeholder="Your feedback (optional)"
                  className="w-full h-24 p-2 border border-gray-300 rounded-lg mb-4"
                ></textarea>
                <div className="flex justify-center gap-4">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderSection;
