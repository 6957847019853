
    import React from 'react';
//const ApiURl = "http://localhost/ecommere_react_with_php_main/ajax";
 const ApiURl = "https://bhe.algo2botsinfotech.com/ajax";
// const ApiURl = "https://localhost/ajax";










    export default   ApiURl ;