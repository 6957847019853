import React, { useEffect, useState, useRef } from "react";
import ApiURL from "../../controllers/Api"; // Make sure this imports your correct base API URL

const ProductReport = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [availabilityFilter, setAvailabilityFilter] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [discountFilter, setDiscountFilter] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);

  const filterPanelRef = useRef(null);

  // Fetch product report data
  const fetchProductReport = async () => {
    try {
      const response = await fetch(`${ApiURL}/getProductReport.php`);
      const data = await response.json();
      console.log(data);

      if (data?.status === "success") {
        setProducts(data.products);
        setFilteredProducts(data.products); // Initialize filtered products
      } else {
        setError("Failed to fetch data.");
      }
    } catch (error) {
      setError("Error fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    let filteredData = [...products];

    // Apply Category Filter
    if (categoryFilter) {
      filteredData = filteredData.filter((product) =>
        product.category_name
          .toLowerCase()
          .includes(categoryFilter.toLowerCase())
      );
    }

    // Apply Availability Filter
    if (availabilityFilter) {
      filteredData = filteredData.filter(
        (product) =>
          checkAvailability(product.is_available).toLowerCase() ===
          availabilityFilter.toLowerCase()
      );
    }

    // Apply Date Range Filters
    if (dateFrom) {
      filteredData = filteredData.filter(
        (product) => new Date(product.created_at) >= new Date(dateFrom)
      );
    }
    if (dateTo) {
      filteredData = filteredData.filter(
        (product) => new Date(product.created_at) <= new Date(dateTo)
      );
    }

    // Apply Discount Filter
    if (discountFilter) {
      if (discountFilter === "applicable") {
        filteredData = filteredData.filter(
          (product) =>
            product.discount_prices.length > 0 && product.discount_prices[0] > 0
        );
      } else if (discountFilter === "notApplicable") {
        filteredData = filteredData.filter(
          (product) =>
            product.discount_prices.length === 0 ||
            product.discount_prices[0] <= 0
        );
      }
    }

    setFilteredProducts(filteredData); // Update filtered products
  };

  const resetFilters = () => {
    setCategoryFilter("");
    setAvailabilityFilter("");
    setDateFrom("");
    setDateTo("");
    setDiscountFilter("");
    setFilteredProducts(products); // Reset to original products list
  };

 
  const exportToCSV = () => {
    // Convert the filtered products to CSV format
    const header = [
      "Serial No",
      "Product ID",
      "Category",
      "Subcategory",
      "HSN Code",
      "IGST",
      "CGST",
      "SGST",
      "Product Name",
      "Sizes",
      "Prices",
      "Availability",
      "Created At",
      "Discounted Info",
      "Discount Available"
    ];
  
    const rows = filteredProducts.map((product, index) => [
      index + 1,
      product.product_id,
      product.category_name,
      product.subcategory_name || "Unknown Subcategory",
      product.hsn_code,
      product.igst,
      product.cgst,
      product.sgst,
      product.product_name,
      product.product_size,
      product.product_price,
      checkAvailability(product.is_available),
      new Date(product.created_at).toLocaleDateString(),
      product.discount_prices.length > 0 &&
        product.discount_prices[0] > 0
        ? product.discount_prices.map(
            (price, i) =>
              `Discount Price: ${price} Min Qty: ${product.discount_min_quantities[i] || "N/A"}`
          ).join(", ")
        : "No Discount",
      product.discount_prices.length > 0 && product.discount_prices[0] > 0
        ? "Discount Applicable"
        : "No Discount"
    ]);
  
    // Combine header and rows into CSV format
    const csvContent = [
      header.join(","),
      ...rows.map(row => row.join(","))
    ].join("\n");
  
    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "product_report.csv"; // File name
    link.click(); // Trigger the download
  };
  
  useEffect(() => {
    fetchProductReport(); // Load data

    // Event listener to detect clicks outside the filter panel
    const handleClickOutside = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setFilterVisible(false); // Close the dropdown if click is outside
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array to ensure effect runs only once on mount


  // Helper function to safely join arrays or return empty string
  const safeJoin = (arr) => {
    return Array.isArray(arr) ? arr.join(", ") : "";
  };

  // Helper function to check availability
  const checkAvailability = (isAvailable) => {
    // Ensure that we compare the value properly, whether it's a string or a number.
    return isAvailable === 1 || isAvailable === "1"
      ? "Available"
      : "Unavailable";
  };

  if (loading) {
    return <p>Loading products...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="container mx-auto">
      <h2 className="text-center text-2xl font-bold mb-4">Product Report</h2>
      <button
        onClick={exportToCSV}
        className="bg-indigo-600 text-white px-1 py-1 rounded-md mt-4 mx-2"
      >
        Export to CSV
      </button>

      {/* Filter Dropdown */}
      <div className="relative inline-block text-left py-2">
        <button
          onClick={() => setFilterVisible(!filterVisible)}
          className="bg-indigo-600 text-white px-2 py-1 rounded-md"
        >
          Filter Products
        </button>

        {/* Dropdown Menu */}
        {filterVisible && (
          <div
            ref={filterPanelRef}
            className="absolute right-0 mt-5 w-56 bg-white border border-gray-300 rounded-md shadow-lg z-50"
          >
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-4">Filter Products</h3>

              {/* Category Filter */}
              <div className="mb-4">
                <label className="block text-sm font-semibold">Category</label>
                <input
                  type="text"
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Search by category"
                />
              </div>

              {/* Availability Filter */}
              <div className="mb-4">
                <label className="block text-sm font-semibold">
                  Availability
                </label>
                <select
                  value={availabilityFilter}
                  onChange={(e) => setAvailabilityFilter(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">All</option>
                  <option value="Available">Available</option>
                  <option value="Unavailable">Unavailable</option>
                </select>
              </div>

              {/* Date Range Filters */}
              <div className="mb-4">
                <label className="block text-sm font-semibold">Date From</label>
                <input
                  type="date"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-semibold">Date To</label>
                <input
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              {/* Discount Filter */}
              <div className="mb-4">
                <label className="block text-sm font-semibold">Discount</label>
                <select
                  value={discountFilter}
                  onChange={(e) => setDiscountFilter(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">All</option>
                  <option value="applicable">Discount Applicable</option>
                  <option value="notApplicable">No Discount</option>
                </select>
              </div>

              {/* Apply and Reset Buttons */}
              <div className="flex justify-between">
                <button
                  onClick={resetFilters}
                  className="bg-gray-400 text-white px-4 py-2 rounded-md"
                >
                  Reset
                </button>
                <button
                  onClick={applyFilters}
                  className="bg-indigo-600 text-white px-4 py-2 rounded-md"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Product Table with Horizontal and Vertical Scroll */}
      <div className="overflow-x-auto overflow-y-auto max-h-[500px] mt-4">
        {" "}
        {/* Added overflow-y-auto for vertical scroll */}
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead className=" bg-[#dcbbfe] text-white sticky top-0 ">
            {" "}
            {/* Sticky header */}
            <tr>
              <th className="border border-gray-300 p-2">Serial No</th>
              <th className="border border-gray-300 p-2">Product ID</th>
              <th className="border border-gray-300 p-2">Category</th>
              <th className="border border-gray-300 p-2">Subcategory</th>
              <th className="border border-gray-300 p-2">HSN Code</th>
              <th className="border border-gray-300 p-2">IGST</th>
              <th className="border border-gray-300 p-2">CGST</th>
              <th className="border border-gray-300 p-2">SGST</th>
              <th className="border border-gray-300 p-2">Product Name</th>
              <th className="border border-gray-300 p-2">Sizes</th>
              <th className="border border-gray-300 p-2">Prices</th>
              <th className="border border-gray-300 p-2">Availability</th>
              <th className="border border-gray-300 p-2">Created At</th>
              <th className="border border-gray-300 p-2">Discounted Info</th>
              <th className="border border-gray-300 p-2">Discount Available</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
              >
                <td className="border border-gray-300 p-2">{index + 1}</td>
                <td className="border border-gray-300 p-2">
                  {product.product_id}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.category_name}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.subcategory_name || "Unknown Subcategory"}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.hsn_code}
                </td>
                <td className="border border-gray-300 p-2">{product.igst}</td>
                <td className="border border-gray-300 p-2">{product.cgst}</td>
                <td className="border border-gray-300 p-2">{product.sgst}</td>
                <td className="border border-gray-300 p-2">
                  {product.product_name}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.product_size}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.product_price}
                </td>
                <td className="border border-gray-300 p-2">
                  {checkAvailability(product.is_available)}
                </td>
                <td className="border border-gray-300 p-2">
                  {new Date(product.created_at).toLocaleDateString()}
                </td>
                <td className="border border-gray-300 p-2">
                  {/* Display both discount and min_quantity in a formatted way */}
                  {product.discount_prices.length > 0 &&
                  product.discount_prices[0] > 0 ? (
                    <div>
                      {product.discount_prices.map((price, index) => (
                        <p key={index}>
                          Discount Price: {price} <br />
                          Min Qty:{" "}
                          {product.discount_min_quantities[index] || "N/A"}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <p>No Discount</p>
                  )}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.discount_prices.length > 0 &&
                  product.discount_prices[0] > 0 ? (
                    <p>Discount Applicable</p>
                  ) : (
                    <p>No Discount</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductReport;