import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import backgrounde from "../images/background.png";
import b1imagee from "../images/mat.jpg";
import b2imagee from "../images/Aboutus.png";
import b3imagee from "../images/apron.png";
import b4imagee from "../images/b4image.png";
import b5imagee from "../images/b5image.png";
import mobileBackground from "../images/mobileBackground.png";

function Sidebar() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const carouselContent = [
    {
      title: "FRIDGE MAT",
      description:
        "Discover our high-quality fridge mats, available in various colors to suit your style. Made from durable fabric, these mats are designed to provide a non-slip surface for your refrigerator shelves and drawers.",
      image: b1imagee,
    },
    {
      title: "TABLE COVER",
      description:
        "Our elegant table covers come in a wide range of designs and colors. Perfect for any occasion, these covers are crafted with superior fabric to protect your ,table and add a touch of class to your home.",
      image: b2imagee,
    },
    {
      title: "MATTRESS COVER",
      description:
        "Our mattress covers are designed to protect ,your mattress from spills stains and dust. They are made of breathable fabric to ensure comfort, while keeping your mattress fresh and clean.",
      image: b4imagee,
    },
    {
      title: "APRON",
      description:
        "Our aprons combine functionality with style. Whether you're cooking or baking, these aprons will keep you clean while adding a ,touch of personality to your kitchen. Made with durable and easy-to-clean fabric.",
      image: b3imagee,
    },
    {
      title: "WASHING MACHINE COVER",
      description:
        "Protect your washing machine from dust and scratches, with our high-quality washing machine covers. Designed for a perfect fit and made from weather-resistant material, these covers ensure long-lasting protection.",
      image: b5imagee,
    },
  ];
  // Auto-slide logic
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselContent.length);
    }, 7000); // Change content every 8 seconds

    return () => clearInterval(interval);
  }, [carouselContent.length]);

  return (
    <div
      className="relative w-full h-[65vh] sm:h-[50vh] lg:h-[50vh] overflow-hidden pt-16 bg-center bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${backgrounde})`, // Default background (for larger screens)
      }}
    >
      {/* Add Tailwind classes for the mobile background */}
      <div
        className="absolute inset-0 w-full h-full bg-black bg-opacity:30 bg-cover bg-center bg-no-repeat sm:hidden"
        style={{ backgroundImage: `url(${mobileBackground})` }}
      />
      {/* Carousel Container */}
      <div className="carousel-container absolute top-0 left-0 w-full h-full bg-black bg-opacity-0 flex items-center justify-center px-5 md:px-20 lg:px-40">
        <Carousel
          selectedItem={currentIndex}
          autoPlay
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          onChange={(index) => setCurrentIndex(index)}
        >
          {carouselContent.map((content, index) => (
            <div
              key={index}
              className="flex flex-col sm:flex-row items-center text-black max-w-full px-4 sm:px-8 md:px-[20px]"
            >
              {/* Image Section */}
              <div className="w-full sm:w-1/2 mb-4 sm:mb-0 flex justify-center">
                <img
                  src={content.image}
                  alt={content.title}
                  className={`rounded-lg max-w-[300px] h-auto object-cover ${
                    content.title === "WASHING MACHINE COVER"
                      ? "max-w-[270px]"
                      : ""
                  } ${content.title === "FRIDGE MAT" ? "max-h-[290px]" : ""} ${
                    content.title === "APRON" ? "max-h-[250px]" : ""
                  } ${content.title === "TABLE COVER" ? "max-h-[280px]" : ""} ${
                    content.title === "MATTRESS COVER" ? "max-h-[310px]" : ""
                  }`}
                />
              </div>

              {/* Text Section */}
              <div className="w-full sm:w-1/2 flex flex-col items-center sm:items-start mt-8 sm:mt-0 md:px-[-20px]">
                <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold mb-4 text-center sm:text-left">
                  {content.title}
                </h1>
                <div className="text-sm sm:text-base leading-snug space-y-1 text-center sm:text-left">
                  {content.description
                    .split(/[.,;!?]/) // Split by commas, periods, semicolons, exclamation marks, and question marks
                    .map((line, idx) =>
                      line.trim() ? <p key={idx}>{line.trim()}</p> : null
                    )}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Sidebar;
