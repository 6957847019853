import { React, useState, useEffect } from "react";
import ApiURl from "../../controllers/Api";
import $ from "jquery";

function UpdateProduct() {
  const [catData, setCatData] = useState([]); // Main categories
  const [subCatData, setSubCatData] = useState([]); // Subcategories
  const [productsInCategory, setProductsInCategory] = useState([]); // Products in subcategory
  const [productData, setProductData] = useState({}); // Product details
  const [selectedCategory, setSelectedCategory] = useState(""); // Selected category
  const [selectedSubCategory, setSelectedSubCategory] = useState(""); // Selected subcategory
  const [selectedProduct, setSelectedProduct] = useState(""); // Selected product
  const [hsnCode, setHsnCode] = useState(""); // HSN code for selected category
  const [message, setMessage] = useState(""); // Error or success messages
  const [size, setSize] = useState(""); // Size selection
  const [selectedSizes, setSelectedSizes] = useState([]); // Sizes selected
  const [sizePrices, setSizePrices] = useState({}); // Prices per size
  const [sizeOptions, setSizeOptions] = useState([]); // Available size options for the current product
  const [selectedProductId, setSelectedProductId] = useState(null); // <-- Added state for selectedProductId 

  // Fetch main categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await $.getJSON(`${ApiURl}/getMainCategory.php`);
        setCatData(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);


  // Fetch subcategories when a category is selected
  useEffect(() => {
    const fetchSubCategories = async (categoryId) => {
      console.log("Fetching subcategories for category ID:", categoryId);
      if (!categoryId) {
        setSubCatData([]); // Reset subcategories if no category is selected
        return;
      }
      try {
        const response = await $.getJSON(
          `${ApiURl}/getsubCategory.php?category_id=${categoryId}`
        );
        setSubCatData(response.data); // Set subcategories for the selected category
      } catch (error) {
        console.error("Error fetching subcategory data:", error);
      }
    };

    // Only fetch subcategories if selectedCategory (category ID) exists
    if (selectedCategory) {
      fetchSubCategories(selectedCategory);
    }
  }, [selectedCategory]); // This useEffect depends on selectedCategory 
  // Fetch products when a subcategory is selected
  useEffect(() => {
    const fetchProducts = async (subcategoryId) => {
      console.log("Fetching products for subcategory ID:", subcategoryId);

      // Check if the subcategoryId is provided (not undefined or empty)
      if (subcategoryId) {
        try {
          // Make sure you are passing the subcategory ID correctly in the API request
          const response = await $.getJSON(
            `${ApiURl}/getupdateProduct.php?sub_category_id=${subcategoryId}`
          );

          // Set products data for the selected subcategory
          setProductsInCategory(response.data);
          console.log(response);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      }
    };

    // Only fetch products if selectedSubCategory (subcategory ID) exists
    if (selectedSubCategory) {
      fetchProducts(selectedSubCategory); // Use the subcategory ID to fetch products
    }
  }, [selectedSubCategory]); // Dependency array to trigger effect when selectedSubCategory changes

    // Fetch sizes when subcategory is selected
// Fetch sizes when selectedProduct or selectedSubCategory changes
useEffect(() => {
  const fetchSizes = async (subCategoryId, productId) => {
    if (!subCategoryId || !productId) return; // Only fetch sizes if both subcategory and product ID exist
    try {
      const response = await $.getJSON(
        `${ApiURl}/getupdatesize.php?product_id=${productId}&sub_category_id=${subCategoryId}`
      );
      console.log(response); // Logging the response to see the data returned from the server
      if (response.success) {
        setSizeOptions(response.data); // Populate size options with the data returned from the API
      } else {
        console.error("Error fetching sizes:", response.message); // Log the error message
      }
    } catch (error) {
      console.error("Error fetching size data:", error); // Catch and log any error
    }
  };

  // Fetch sizes for the current sub-category if both selectedSubCategory and selectedProduct are available
  if (selectedSubCategory && selectedProduct) {
    fetchSizes(selectedSubCategory, selectedProduct); // Pass selectedProduct instead of selectedProductId
  }
}, [selectedSubCategory, selectedProduct]); // Fetch sizes when selectedSubCategory or selectedProduct changes

// Handle product selection (update the selectedProduct state)
const handleProductSelection = (productId) => {
  setSelectedProduct(productId); // Set the selected product ID when a product is selected
};


// Fetch product data when selectedProduct changes
useEffect(() => {
    if (selectedProduct) {
      const fetchProductData = async () => {
        try {
          const response = await $.getJSON(
            `${ApiURl}/getProducts.php?product=${selectedProduct}`
          );
          console.log(response);
          if (response.success) {
            setProductData(response.products[0]);  // Set the product data
            setSelectedSizes(response.product.sizes); // Set available sizes
            const prices = response.product.sizes.reduce((acc, size, idx) => {
              acc[size] = response.product.prices[idx]; // Map sizes to their prices
              return acc;
            }, {});
            setSizePrices(prices);  // Set size prices
          }
        } catch (error) {
          console.error("Error fetching product data:", error);
        }
      };
      fetchProductData();
    }
  }, [selectedProduct]);  // Dependency on selectedProduct
  
  console.log(selectedProduct);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    
    // Debug: Check if variables have values
    console.log("selectedProduct:", selectedProduct);
    console.log("productData:", productData);
    console.log("selectedCategory:", selectedCategory);
    console.log("subCategoryId:", selectedSubCategory);  // Add a log for subCategoryId
    console.log("selectedSizes:", selectedSizes);
    console.log("sizePrices:", sizePrices);
  
    formData.append("productId", selectedProduct);
    formData.append("productPrice", productData.productPrice);
    formData.append("description", productData.description);
    formData.append("brand", productData.brand);
    formData.append("categoryId", selectedCategory);
    formData.append("subCategoryId", selectedSubCategory);  // Append subCategoryId
  
    // Add sizes and their prices
    selectedSizes.forEach((size) => {
      console.log(`Appending size: ${size} with price: ${sizePrices[size] || 0}`);
      formData.append("sizes[]", size);
      formData.append(`price_${size}`, sizePrices[size] || 0);
    });
  
    // Debug: Log the FormData before sending it
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }
  
    try {
      const response = await $.ajax({
        url: `${ApiURl}/addupdatesize.php`,
        type: "POST",
        data: formData,
        processData: false,
        contentType: false,
      });
  
      console.log(response); // Check the response from the server
  
      if (response.success) {
        alert("Product updated successfully");
      } else {
        setMessage("Error updating product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };
  
  

  const handleSizeChange = (e) => {
    setSize(e.target.value); // Update selected size
  };

  const handleAddSize = () => {
    if (size && !selectedSizes.includes(size)) {
      setSelectedSizes([...selectedSizes, size]); // Add size to the list
      setSize(""); // Clear the size input after adding
    }
  };


  const handlePriceChange = (e, selectedSize) => {
    const newPrice = e.target.value;
    setSizePrices((prevPrices) => ({
      ...prevPrices,
      [selectedSize]: parseFloat(newPrice),
    }));
  };

  const handleRemoveSize = (sizeToRemove) => {
    setSelectedSizes(selectedSizes.filter((size) => size !== sizeToRemove)); // Remove size from selected sizes
    const newSizePrices = { ...sizePrices };
    delete newSizePrices[sizeToRemove]; // Remove price for the removed size
    setSizePrices(newSizePrices);
  };

  return (
    <div className="container mx-auto p-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Select Category */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Category
          </label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="" hidden>
              Select Category
            </option>
            {catData && catData.length > 0 ? (
              catData.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.category_name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No categories available
              </option>
            )}
          </select>
        </div>

        {/* Select Subcategory */}
        {selectedCategory && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Subcategory
            </label>
            <select
              value={selectedSubCategory} // Keep selectedSubCategory as the value for the dropdown
              onChange={(e) => setSelectedSubCategory(e.target.value)} // Update selectedSubCategory with the selected value (ID)
              className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="" hidden>
                Select Subcategory
              </option>
              {subCatData && subCatData.length > 0 ? (
                subCatData.map((subCategory) => (
                  <option
                    key={subCategory.category_id}
                    value={subCategory.category_id}
                  >
                    {subCategory.category_name}{" "}
                    {/* Display the category name */}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No subcategories available
                </option>
              )}
            </select>
          </div>
        )}

        {/* Select Product */}
        {selectedSubCategory && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Product
            </label>
            <select
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="" hidden>
                Select Product
              </option>
              {productsInCategory && productsInCategory.length > 0 ? (
                // Get distinct products by product name
                [
                  ...new Map(
                    productsInCategory.map((product) => [
                      product.name,
                      product,
                    ])
                  ).values(),
                ].map((product) => (
                  <option key={product.product_id} value={product.product_id}>
                    {product.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No products available
                </option>
              )}
            </select>
          </div>
        )}

        {/* s
        {/* Sizes Section */}
        <div className="sm:col-span-2">
          <label
            htmlFor="size"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Sizes
          </label>
          <div className="flex space-x-2">
            <select
              id="size"
              value={size}
              onChange={handleSizeChange}
              className="border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
            >
              <option value="" hidden>
                Select Size
              </option>
              {sizeOptions && sizeOptions.length > 0 ? (
                sizeOptions.map((option) => (
                  <option key={option.size} value={option.size}>
                    {option.size}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No sizes available
                </option>
              )}
            </select>
            <button
              type="button"
              onClick={handleAddSize}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg"
            >
              Add Size
            </button>
          </div>

          {selectedSizes.length > 0 && (
            <div className="mt-4 space-y-2">
              {selectedSizes.map((selectedSize) => (
                <div key={selectedSize} className="flex items-center">
                  <span className="mr-2">{selectedSize}</span>
                  <input
                    type="number"
                    placeholder="Enter Price"
                    value={sizePrices[selectedSize] || ""}
                    onChange={(e) => handlePriceChange(e, selectedSize)}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveSize(selectedSize)}
                    className="ml-2 text-red-500"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* End of Sizes Section */}

        {/* Error Message */}
        {message && <p className="text-red-600">{message}</p>}

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-indigo-500 text-white py-3 rounded-md hover:bg-indigo-600"
        >
          Update Product
        </button>
      </form>
    </div>
  );
}

export default UpdateProduct;
