import { useEffect, useState, useRef } from "react"; // No changes here
import { Link, useNavigate } from "react-router-dom";
import { SlUser } from "react-icons/sl";
import { IoMdHeartEmpty } from "react-icons/io";
import { PiShoppingCartSimple } from "react-icons/pi";
import LogoutButton from "./LogoutButton";
import SearchBar from "./Searchbar";
import ApiURl from "../controllers/Api";
import logo from "../images/logo.png";
import back from "../images/back.png";
import { GiButtonFinger } from "react-icons/gi";

function Navbar() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(""); // Role state to control Admin button visibility
  const [catData, setCatData] = useState([]); // All categories fetched from API
  const [showCategories, setShowCategories] = useState(false); // Show all categories on hover
  const [loggedIn, setLoggedIn] = useState(false);
  const menuRef = useRef(null);

  // Ref to store the current login state to prevent unnecessary updates
  const loggedInRef = useRef(false);

  // Fetch the user's role from localStorage on component mount and update state
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role) {
      setUserRole(role);  // Set the userRole if it exists
      setLoggedIn(true);   // Set loggedIn to true if role is found
      loggedInRef.current = true;
    } else {
      setLoggedIn(false); // Set loggedIn to false if no role is found
      loggedInRef.current = false;
    }
  }, []); // Only run this effect on initial mount

  // Effect to check and refresh the login state silently every second
  useEffect(() => {
    const interval = setInterval(() => {
      const role = localStorage.getItem("role");
      if (role !== null) {
        // Only set loggedIn if the value has changed
        if (!loggedInRef.current) {
          loggedInRef.current = true;
          setLoggedIn(true); // Update state if necessary
          setUserRole(role); // Update the role
        }
      } else {
        // Log out if no role is found
        if (loggedInRef.current) {
          loggedInRef.current = false;
          setLoggedIn(false); // Update state if necessary
          setUserRole("");    // Reset the role
        }
      }
    }, 1000); // Refresh every second

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []); // Empty dependency array to ensure the effect runs once when the component mounts

  // Fetch categories on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${ApiURl}/getMainCategory.php`);
        const data = await response.json();

        if (data && data.data) {
          const activeCategories = data.data.filter(
            (category) => category.is_active === "1"
          );
          setCatData(activeCategories);
        } else {
          console.error("Unexpected API response structure:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Fetch categories once on component mount

  const handleLogout = () => {
    localStorage.removeItem("role");
    setLoggedIn(false); // Trigger logout state update
    setUserRole(""); // Clear user role after logout
  };

  const handleAdminClick = () => {
    navigate("/admin");
  };

  const toggleMenu = () => {
    document.getElementById("mobile-menu").classList.toggle("hidden");
  };

  const fetchSearchResults = async (query) => {
    const response = await fetch("/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    });

    const data = await response.json();
    console.log(data);
  };

  const handleCategoryClick = (categoryId) => {
    navigate(`/Products/${categoryId}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        document.getElementById("mobile-menu").classList.add("hidden");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-gradient-to-r from-[#C77A2B] via-[#E5AA70] to-[#C77A2B] sticky top-0 z-50">
      <nav className="bg-transparent border-b border-gray-300 shadow-sm">
        <div className="container mx-auto flex items-center justify-between py-1 px-3 md:px-6">
          <div className="font-bold text-gray-900">
            <Link to="/">
              <img
                src={logo}
                alt="Bhatia Emporium Logo"
                className="w-24 "
              />
            </Link>
            {/* <button className="top-28 sm:top-32 md:top-28 absolute sm:mt-4" */}
           <button className="absolute top-28 sm:top-32 md:top-28 flex items-center justify-center space-x-2 px-2 py-1  rounded-full text-black shadow-md hover:bg-indigo-600 transition-all"

          onClick={() => navigate(-1)}>
           
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-5 h-5"
          >
            <path d="M15 18l-6-6 6-6" />
          </svg>
          back
         
          </button>
          </div>
          
         

          <div className="hidden lg:block w-2/4 relative">
            <SearchBar onsearch={fetchSearchResults} />
          </div>

          <div className="flex items-center space-x-4">
            <div className="flex flex-col items-center text-white text-lg lg:text-base">
              <Link to="/dashboard">
                <SlUser className="w-6 h-6 sm:w-5 sm:h-5" />
              </Link>
              <span className="text-xs sm:text-[10px]">MyOrders</span>
            </div>
            <div className="flex flex-col items-center text-white text-lg lg:text-base">
              <Link to="/wishlist">
                <IoMdHeartEmpty className="w-6 h-6 sm:w-5 sm:h-5" />
              </Link>
              <span className="text-xs sm:text-[10px]">Wishlist</span>
            </div>
            <div className="flex flex-col items-center text-white text-lg lg:text-base">
              <Link to="/cart">
                <PiShoppingCartSimple className="w-6 h-6 sm:w-5 sm:h-5" />
              </Link>
              <span className="text-xs sm:text-[10px]">Cart</span>
            </div>
            <LogoutButton loggedIn={loggedIn} onLogout={handleLogout} />
            <button
              className="lg:hidden text-white bg-[#DD9045] rounded-full p-2"
              onClick={toggleMenu}
            >
              <i className="fas fa-bars"></i>
            </button>
            {userRole === "admin" && (
              <button
                onClick={handleAdminClick}
                className="hidden lg:block text-white bg-[#DD9045] px-3 py-1 rounded-md"
              >
                Admin
              </button>
            )}
          </div>
        </div>

        <div className="lg:hidden px-3 pb-4">
          <SearchBar onsearch={fetchSearchResults} />
        </div>

        <div
          id="mobile-menu"
          ref={menuRef}
          className="hidden flex-col lg:flex lg:flex-row lg:justify-center items-start lg:items-left"
        >
          <ul className="flex flex-col lg:flex-row lg:space-x-5 lg:py-2 space-y-2 lg:space-y-0 text-white text-sm font-bold">
            {/* Display only first 5 categories in the navbar */}
            {catData.slice(0, 5).map((row, index) => (
              <div
                key={row.id}
                onClick={() => handleCategoryClick(row.id)}
                className={`nav-item w-full lg:w-auto p-1 sm:bg-[#DD9045] sm:text-white sm:font-bold sm:shadow-md sm:rounded-md flex items-center justify-center hover:shadow-lg hover:scale-105 transition-transform duration-300 whitespace-nowrap`}
                style={{
                  animationDelay: `${index * 0.2}s`,
                }}
              >
                <span
                  className="text-left lg:leading-none lg:py-1 lg:px-2 cursor-pointer"
                  title={row.category_name}
                >
                  {row.category_name}
                </span>
              </div>
            ))}

            {userRole === "admin" && (
              <div className="w-full lg:w-auto">
                <button
                  onClick={handleAdminClick}
                  className="bg-black text-white hover:text-[#DD9045] lg:hidden"
                >
                  Admin
                </button>
              </div>
            )}

            {/* Arrow button to show all categories on hover */}
            <button
              className="w-[100px] bg-transparent py-1 px-1 rounded-lg transition duration-300 flex flex-col justify-center items-center  relative hover:scale-110" // Increase box size on hover
              onMouseEnter={() => setShowCategories(true)} // Show categories on hover
              onMouseLeave={() => setShowCategories(false)} // Hide categories when mouse leaves
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="28px"
                height="28px"
              >
                <path d="M31.007 35.001v-7l-28-2v-6l27-1.928v1.424c0 .276.224.5.5.5.275 0 .5-.224.5-.5v-8.496l14 12L31.007 35.001zM27.515 20.704c.111-.004.199-.1.195-.211-.004-.111-.1-.199-.211-.195-7.348-.083-14.689.245-22 1.004-.109.004-.195.097-.191.207.004.109.097.195.207.191C12.86 21.8 20.208 21.461 27.515 20.704zM39.527 20.723c.312-.428-3.455-3.597-4.963-4.549-.459-.311-1.079-.191-1.389.275-.305.452-.185 1.072.272 1.387C35.076 18.831 39.181 21.197 39.527 20.723zM24.007 46.999c10.488 0 19 .225 19 .501s-8.512.501-19 .501-19-.225-19-.501S13.519 46.999 24.007 46.999z" />
              </svg>

              {/* Show categories only when hovered */}
              {showCategories && (
                <div className="absolute top-10 left-0 w-full bg-[#DD9045] text-white shadow-lg rounded-lg z-50">
                  <table className="table-auto w-full left text-xs text-white p-3 z-50">
                    <thead>
                      <tr>
                        <th className="p-1 text-left">Category Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {catData.map((category) => (
                        <tr
                          key={category.id}
                          onClick={() => handleCategoryClick(category.id)}
                          className="cursor-pointer hover:bg-[#DD9045]"
                        >
                          <td className="p-2">{category.category_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </button>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
