import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiURL from "../../controllers/Api";
import logo from "../../images/logo.png";

const DetailedReport = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [customerNameFilter, setCustomerNameFilter] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const filterPanelRef = useRef(null);

  const navigate = useNavigate();

  const fetchOrderDetails = async () => {
    try {
      const response = await fetch(`${ApiURL}/getReportData.php`);
      const data = await response.json();

      if (data?.success) {
        const orderData = data?.data || {}; // Assuming `data.data` contains the orders

        let updated = false;
        console.log(orderData);
        // Check if the orders data has changed and update the state only if there’s a change
        if (JSON.stringify(orderData.orders) !== JSON.stringify(orders)) {
          setOrders(orderData?.recent_orders || []);
          updated = true;
        }

        if (updated) {
          setLoading(false); // Stop loading state once data is fetched
        }
      } else {
        setError(data?.message || "Failed to fetch order details.");
        setLoading(false); // Stop loading even if there's an error
      }
    } catch (error) {
      setError(
        "An error occurred while fetching order details. Please try again."
      );
      setLoading(false); // Stop loading on error
    }
  };

  const applyFilters = () => {
    const filtered = orders.filter((order) => {
      let matches = true;

      // Filter by status
      if (statusFilter && order.status !== statusFilter) {
        matches = false;
      }

      // Filter by date range
      if (dateFrom && new Date(order.order_date) < new Date(dateFrom)) {
        matches = false;
      }
      if (dateTo && new Date(order.order_date) > new Date(dateTo)) {
        matches = false;
      }

      // Filter by customer name
      if (
        customerNameFilter &&
        !order.full_name
          .toLowerCase()
          .includes(customerNameFilter.toLowerCase())
      ) {
        matches = false;
      }

      return matches;
    });

    setFilteredOrders(filtered); // Update the filtered orders state
    setFilterVisible(false); // Close the filter panel after applying
  };

  // Reset filters
  const resetFilters = () => {
    setStatusFilter("");
    setDateFrom("");
    setDateTo("");
    setCustomerNameFilter("");
    setFilteredOrders(orders); // Reset to the original data
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  useEffect(() => {
    if (orders.length > 0) {
      setFilteredOrders(orders); // Make sure it's visible by default
    }
  }, [orders]);

  // Show loading message if the data is still being fetched
  if (loading) {
    return <p>Loading orders...</p>;
  }

  // Show error message if there's an error fetching data
  if (error) {
    return <p className="text-red-500">Error: {error}</p>;
  }

  // Show message if no orders are available
  if (!orders || orders.length === 0) {
    return <p>No orders available.</p>;
  }

  // Function to convert the order data to CSV
  const exportToCSV = () => {
    const header = [
      "SNo",
      "Order ID",
      "Customer Name",
      "Company Name",
      "Order Date",
      "Status",
      "Product Name",
      "Hsn Code",
      "Quantity",
      "Size",
      "Price",
      "Total",
      "IGST",
      "SGST",
      "CGST",
      "Total",
      "Grand Amount",
      "Rounding Difference",
      "Payble Amount",
    ];

    const rows = orders.flatMap((order, orderIndex) => {
      const products = order.products
        ? order.products.split(";").map((prod) => {
            const [
              productId,
              productName,
              description,
              imgPath,
              quantity,
              price,
              size,
              totalAmount,
              igst,
              sgst,
              cgst,
              grandTotal,
              roundedAmount,
              roundingDifference,
              hsn_code,
            ] = prod.split("|");

            return {
              productName,

              quantity,
              size,
              price,
              totalAmount,
              igst,
              sgst,
              cgst,
              grandTotal,
              roundedAmount,
              roundingDifference,
              hsn_code,
            };
          })
        : [];

      // Calculate the total grand total for the order
      const orderGrandTotal = products.reduce(
        (sum, product) => sum + parseFloat(product.grandTotal),
        0
      );

      // Round the grand total to the nearest integer
      const roundedGrandTotal = Math.round(orderGrandTotal);

      // Calculate rounding difference and invert the sign
      const roundingDifference = (orderGrandTotal - roundedGrandTotal) * -1;

      // Update the rounded amount
      const orderRoundedAmount = roundedGrandTotal;

      return products.map((product, productIndex) => [
        `${orderIndex + 1}.${productIndex + 1}`,
        order.order_id,
        order.full_name,
        order.address_line1,
        new Date(order.order_date).toLocaleDateString(),
        order.status,

        product.productName,
        product.hsn_code,
        product.quantity,
        product.size,
        parseFloat(product.price).toFixed(2),
        parseFloat(product.totalAmount).toFixed(2),
        parseFloat(product.igst).toFixed(2),
        parseFloat(product.sgst).toFixed(2),
        parseFloat(product.cgst).toFixed(2),
        parseFloat(product.grandTotal).toFixed(2),
        productIndex === products.length - 1
          ? `${orderGrandTotal.toFixed(2)}`
          : "",
        productIndex === products.length - 1
          ? `${roundingDifference.toFixed(2)}`
          : "",
        productIndex === products.length - 1
          ? `${orderRoundedAmount.toFixed(2)}`
          : "",
      ]);
    });

    // Create CSV content
    const csvContent = [
      header.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    // Create a Blob for CSV download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      link.href = URL.createObjectURL(blob);
      link.download = "order_details.csv";
      link.click();
    }
  };

  return (
    <div className="w-[180vh] bg-white shadow-lg rounded-lg p-2 my-5 border-t">
      <div id="receipt-content">
        {/* <div className="text-center">
          <h1 className="w-28 mx-auto mb-2">
            <img src={logo} alt="Bhatia Emporium logo" />
          </h1>
          <p className="text-gray-600 text-lg font-semibold">Bhatia Emporium Order Report</p>
        </div> */}

        {/* Export Button */}
        <div className="text-left">
          <button
            onClick={exportToCSV}
            className="bg-blue-500 text-white py-1 px-2 rounded-md mt-4"
          >
            Export to CSV
          </button>
        </div>

        {/* Filter Dropdown */}
        <div className="relative inline-block float-right py-2 text-left py-2 ">
          <button
            onClick={() => setFilterVisible(!filterVisible)}
            className="bg-indigo-600 text-white px-2 py-1 rounded-md"
          >
            Filter Orders
          </button>

          {/* Dropdown Menu */}
          {filterVisible && (
            <div
              ref={filterPanelRef}
              className="absolute right-0 mt-5 w-56 bg-white border border-gray-300 rounded-md shadow-lg z-50"
            >
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-4">Filter Orders</h3>

                {/* Status Filter */}
                <div className="mb-4">
                  <label className="block text-sm font-semibold">
                    Order Status
                  </label>
                  <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option value="">All</option>
                    <option value="completed">Completed</option>
                    <option value="pending">Pending</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>

                {/* Date Range Filters */}
                <div className="mb-4">
                  <label className="block text-sm font-semibold">
                    Date From
                  </label>
                  <input
                    type="date"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-semibold">Date To</label>
                  <input
                    type="date"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Customer Name Filter */}
                <div className="mb-4">
                  <label className="block text-sm font-semibold">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    value={customerNameFilter}
                    onChange={(e) => setCustomerNameFilter(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Search by name"
                  />
                </div>

                {/* Apply and Reset Buttons */}
                <div className="flex justify-between ">
                  {" "}
                  {/* Added space-x-4 for spacing between buttons */}
                  <button
                    onClick={resetFilters}
                    className="bg-gray-400 text-white px-4 py-2 rounded-md"
                  >
                    Reset
                  </button>
                  <button
                    onClick={applyFilters}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Orders Table */}
        <div className="mt-6">
          <h2 className="text-lg font-semibold text-center text-gray-800">
            Order Details
          </h2>

          <div className="overflow-x-auto max-h-96 relative">
            <table className="w-full mt-4 border border-gray-300 text-sm">
              <thead>
                <tr className="bg-[#dcbbfe] text-white sticky top-0">
                  <th className="border border-gray-300 p-1 text-left">SNo</th>
                  <th className="border border-gray-300 p-1 text-left">
                    Order ID
                  </th>
                  <th className="border border-gray-300 p-1 text-left">
                    Customer Name
                  </th>
                  <th className="border border-gray-300 p-1 text-left">
                    Company Name
                  </th>
                  <th className="border border-gray-300 p-1 text-left">
                    Order Date
                  </th>
                  <th className="border border-gray-300 p-1 text-left">
                    Status
                  </th>
                  <th className="border border-gray-300 p-1 text-left">
                    Product Name
                  </th>
                  <th className="border border-gray-300 p-1 text-center">
                    Hsn Code
                  </th>
                  <th className="border border-gray-300 p-1 text-center">
                    Quantity
                  </th>
                  <th className="border border-gray-300 p-1 text-center">
                    Size
                  </th>
                  <th className="border border-gray-300 p-1 text-right">
                    Price
                  </th>
                  <th className="border border-gray-300 p-1 text-right">
                    Total
                  </th>
                  <th className="border border-gray-300 p-1 text-right">
                    IGST
                  </th>
                  <th className="border border-gray-300 p-1 text-right">
                    SGST
                  </th>
                  <th className="border border-gray-300 p-1 text-right">
                    CGST
                  </th>
                  <th className="border border-gray-300 p-1 text-right">
                    Total
                  </th>
                  <th className="border border-gray-300 p-1 text-right w-32 font-bold">
                    Grand Amount
                  </th>
                  <th className="border border-gray-300 p-1 text-right w-32 font-bold">
                    Rounding Difference
                  </th>
                  <th className="border border-gray-300 p-1 text-right w-32 font-bold">
                    Payble Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((order, orderIndex) => {
                  const products = order.products
                    ? order.products.split(";").map((prod) => {
                        const [
                          productId,
                          productName,
                          description,
                          imgPath,
                          quantity,
                          price,
                          size,
                          totalAmount,
                          igst,
                          sgst,
                          cgst,
                          grandTotal,
                          roundedAmount,
                          roundingDifference,
                          hsn_code,
                        ] = prod.split("|");

                        return {
                          productId,
                          productName,
                          description,
                          imgPath,
                          quantity,
                          price,
                          size,
                          totalAmount,
                          igst,
                          sgst,
                          cgst,
                          grandTotal,
                          roundedAmount,
                          roundingDifference,
                          hsn_code,
                        };
                      })
                    : [];

                  // Calculate the total grand total for the order
                  const orderGrandTotal = products.reduce(
                    (sum, product) => sum + parseFloat(product.grandTotal),
                    0
                  );

                  // Round the grand total to the nearest integer
                  const roundedGrandTotal = Math.round(orderGrandTotal);

                  // Calculate rounding difference and invert the sign
                  const roundingDifference =
                    (orderGrandTotal - roundedGrandTotal) * -1;

                  // Update the rounded amount
                  const orderRoundedAmount = roundedGrandTotal;

                  return products.map((product, productIndex) => (
                    <tr
                      key={`${orderIndex}-${productIndex}`}
                      className={`${
                        (productIndex + orderIndex) % 2 === 0
                          ? "bg-gray-100"
                          : "bg-white"
                      } hover:bg-gray-200`}
                    >
                      <td className="border border-gray-300 p-1 text-left">
                        {orderIndex + 1}.{productIndex + 1}
                      </td>
                      {productIndex === 0 && (
                        <>
                          <td
                            rowSpan={products.length}
                            className="border border-gray-300 p-1 text"
                          >
                            {order.order_id}
                          </td>
                          <td
                            rowSpan={products.length}
                            className="border border-gray-300 p-1"
                          >
                            {order.full_name}
                          </td>
                          <td
                            rowSpan={products.length}
                            className="border border-gray-300 p-1"
                          >
                            {order.address_line1}
                          </td>
                          <td
                            rowSpan={products.length}
                            className="border border-gray-300 p-1"
                          >
                            {new Date(order.order_date).toLocaleDateString()}
                          </td>
                          <td
                            rowSpan={products.length}
                            className="border border-gray-300 p-1"
                          >
                            {order.status}
                          </td>
                        </>
                      )}
                      <td className="border border-gray-300 p-1">
                        {product.productName}
                      </td>
                      <td className="border border-gray-300 p-1 text-center">
                        {product.hsn_code}
                      </td>
                      <td className="border border-gray-300 p-1 text-center">
                        {product.quantity}
                      </td>
                      <td className="border border-gray-300 p-1 text-center">
                        {product.size}
                      </td>
                      <td className="border border-gray-300 p-1 text-right">
                        ₹{parseFloat(product.price).toFixed(2)}
                      </td>
                      <td className="border border-gray-300 p-1 text-right">
                        ₹{parseFloat(product.totalAmount).toFixed(2)}
                      </td>
                      <td className="border border-gray-300 p-1 text-right">
                        ₹{parseFloat(product.igst).toFixed(2)}
                      </td>
                      <td className="border border-gray-300 p-1 text-right">
                        ₹{parseFloat(product.sgst).toFixed(2)}
                      </td>
                      <td className="border border-gray-300 p-1 text-right">
                        ₹{parseFloat(product.cgst).toFixed(2)}
                      </td>
                      <td className="border border-gray-300 p-1 text-right">
                        ₹{parseFloat(product.grandTotal).toFixed(2)}
                      </td>
                      <td className="border border-gray-300 p-1 text-right">
                        {productIndex === products.length - 1
                          ? `₹${orderGrandTotal.toFixed(2)}`
                          : ""}
                      </td>
                      <td className="border border-gray-300 p-1 text-right">
                        {productIndex === products.length - 1
                          ? `₹${roundingDifference.toFixed(2)}`
                          : ""}
                      </td>
                      {productIndex === products.length - 1 && (
                        <td className="border border-gray-300 p-1 text- font-bold bg-gray-200">
                          ₹{orderRoundedAmount.toFixed(2)}
                        </td>
                      )}
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedReport;
