import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import ApiURl from "../controllers/Api";
import imgLocation from "../controllers/imagePath";

const ViewDetails = () => {
  const { order_id } = useParams();
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const user_id = localStorage.getItem("user_id");

        if (!user_id) {
          setError("User ID is missing. Please log in.");
          setLoading(false);
          return;
        }

        const response = await fetch(
          `${ApiURl}/getOrderDetails.php?order_id=${order_id}&user_id=${user_id}`
        );

        const data = await response.json();

        if (data.success) {
          setOrderDetails(data.data);
        } else {
          throw new Error(data.message || "No data available");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [order_id]);

  if (loading) {
    return (
      <div className="text-center mt-10 text-gray-600">
        Loading order details...
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 mt-10">{`Error: ${error}`}</div>
    );
  }

  const orderInfo = orderDetails.length > 0 ? orderDetails[0] : null;

  return (
    <div className="container mx-auto p-12 bg-gray-100 min-h-screen">
      {orderInfo && (
        <div className="max-w-xl mx-auto bg-white p-2 rounded-lg shadow-md">

          {/* Order Information Section */}
          <div className="bg-indigo-50 p-3 rounded-md shadow-sm mb-3">
            <h3 className="text-sm font-semibold text-indigo-700 mb-1 text-center">
              Order Summary
            </h3> {/* Centered heading */}

            

            <div className="flex justify-between items-start">
              {/* Left: Order Details */}
              <div className="flex-1">
                <p className="text-xs mb-1 text-gray-800">
                  <strong>Order ID:</strong> {orderInfo.order_id}
                </p>
                <p className="text-xs mb-1 text-gray-800">
                  <strong>Order Date:</strong> {orderInfo.date}
                </p>
                <p className="text-xs mb-1 text-gray-800">
                  <strong>Status:</strong> {orderInfo.status}
                </p>
                <p className="text-xs mb-1 text-gray-800">
                  <strong>Total Amount:</strong> ₹{orderInfo.total_amount}
                </p>
                <p className="text-xs mb-1 text-gray-800">
                  <strong>Payment Status:</strong> {orderInfo.payment_status}
                </p>
                <p className="text-xs mb-1 text-gray-800">
                  <strong>Payment Method:</strong> {orderInfo.payment_method}
                </p>
              </div>

              {/* Right: User Name and Shipping Address */}
              <div className="flex-1 pl-4">
                <div className="mt-2 bg-indigo-100 p-3 rounded-md shadow-sm">
                  <div>
                    <h2 className="text-md font-bold text-indigo-600">
                      Username- {orderInfo.first_name} {orderInfo.last_name}
                    </h2>
                    <p className="text-xs text-gray-600 mt-1">User ID: {orderInfo.user_id}</p>
                  </div>
                  <p className="text-gray-800 font-bold text-xs mb-1">
                    <strong>Shipping Address:</strong>
                  </p>
                  <p className="text-gray-700 text-xs">{orderInfo.phone}</p>
                  <p className="text-gray-700 text-xs">{orderInfo.address_line1}</p>
                  <p className="text-gray-700 text-xs">{orderInfo.address_line2}</p>
                  <p className="text-gray-700 text-xs">{orderInfo.landmark}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Products Section */}
          <h3 className="text-sm font-semibold text-indigo-700 mb-3">Products</h3>
          
          <div className="hidden md:block">
            <table className="table-auto w-full border-collapse border border-gray-300 text-xs">
              <thead>
                <tr className="bg-indigo-100 text-indigo-800">
                  <th className="border border-gray-300 p-2">Name</th>
                  <th className="border border-gray-300 p-2">Size</th>
                  <th className="border border-gray-300 p-2">Quantity</th>
                  <th className="border border-gray-300 p-2">Price</th>
                </tr>
              </thead>
              <tbody>
                {orderInfo.products && orderInfo.products.length > 0 ? (
                  orderInfo.products.map((item) => (
                    <tr key={item.product_id} className="text-gray-800">
                      <td className="border border-gray-300 p-2">
                        <div className="flex items-center">
                          <img
                            src={`${imgLocation}/${item.img_path}`}
                            alt={item.name}
                            className="w-8 h-8 object-contain rounded-md mr-2"
                          />
                          <span className="font-semibold text-xs text-gray-900">
                            {item.name}
                          </span>
                        </div>
                      </td>
                      <td className="border border-gray-300 p-2 text-xs">{item.size}</td>
                      <td className="border border-gray-300 p-2 text-xs">{item.quantity}</td>
                      <td className="border border-gray-300 p-2 text-xs">₹{item.price}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="border border-gray-300 p-2 text-center text-gray-600"
                    >
                      No products available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Card Layout for Mobile */}
          <div className="grid grid-cols-1 gap-2 md:hidden">
            {orderInfo.products && orderInfo.products.length > 0 ? (
              orderInfo.products.map((item) => (
                <div
                  key={item.product_id}
                  className="bg-white border rounded-lg shadow-sm p-3"
                >
                  <img
                    src={`${imgLocation}/${item.img_path}`}
                    alt={item.name}
                    className="w-full h-24 object-contain rounded-md mb-2"
                  />
                  <h4 className="text-xs font-medium text-gray-900 text-center mb-1">
                    {item.name}
                  </h4>
                  <div className="text-xs text-gray-800 space-y-1">
                    <p>
                      <strong>Size:</strong> {item.size}
                    </p>
                    <p>
                      <strong>Quantity:</strong> {item.quantity}
                    </p>
                    <p>
                      <strong>Price:</strong> ₹{item.price}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="col-span-full text-center text-gray-600">
                No products available.
              </p>
            )}
          </div>

          <button
            onClick={() => navigate('/order-section')}
            className="mt-3 px-3 py-1 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 text-xs"
          >
            Back to Previous page
          </button>
        </div>
      )}
    </div>
  );
};

export default ViewDetails;