import { React, useState, useEffect } from "react";
import img1 from "./apron with cap and checks.png";
import $ from "jquery";
import ApiURl from "../../controllers/Api";
function AddProduct() {
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [hsnCodeData, setHsnCodeData] = useState([]); // State to store HSN codes
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [description, setDescription] = useState("");
  const [size, setSize] = useState("");
  const [sizeOptions, setSizeOptions] = useState([]); // State for sizes
  const [brand, setBrand] = useState("");
  const [currentCategoryId, setCurrentCategoryId] = useState(0);
  const [currentSubCategoryId, setCurrentSubCategoryId] = useState(0);
  const [currentHsnCodeId, setCurrentHsnCodeId] = useState(0); // State for selected HSN code
  const [imageFile, setImageFile] = useState(null);
  const [stockQuantity, setStockQuantity] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [discounts, setDiscounts] = useState({}); // New state to store discounts
  const [minQuantity, setMinQuantity] = useState(""); // Min quantity for discount
  const [discountPrices, setDiscountPrices] = useState(""); // Discounted price
  const [sizeDiscounts, setSizeDiscounts] = useState({});
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [sizePrices, setSizePrices] = useState({});
  const [minQuantities, setMinQuantities] = useState({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await $.getJSON(`${ApiURl}/getMainCategory.php`);
        setCatData(response.data);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };
    const fetchSizes = async (subCategoryId) => {
      try {
        const response = await $.getJSON(
          `${ApiURl}/getSizes.php?sub_category_id=${subCategoryId}`
        );
        if (response.success) {
          setSizeOptions(response.data); // Populate size options
        } else {
          console.error("Error fetching sizes:", response.message);
        }
      } catch (error) {
        console.error("Error fetching size data:", error);
      }
    };
    fetchCategories();
    // Fetch sizes for the current sub-category (if any)
    if (currentSubCategoryId) {
      fetchSizes(currentSubCategoryId);
    }
  }, [currentSubCategoryId, currentCategoryId]); // Re-run when category or sub-category changes
  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    setCurrentCategoryId(selectedCategoryId);
    setCurrentSubCategoryId(0); // Reset sub-category when category changes
    setCurrentHsnCodeId(0); // Reset HSN code when category changes
    fetchSubCategories(selectedCategoryId);
    fetchHsnCodes(selectedCategoryId); // Fetch HSN codes for the selected category
  };
  const fetchHsnCodes = async (categoryId) => {
    try {
      const response = await $.getJSON(
        `${ApiURl}/get_hsn_codes.php?category_id=${categoryId}`
      );
      if (response.success) {
        setHsnCodeData(response.data); // Populate HSN codes
      } else {
        console.error("Error fetching HSN codes:", response.message);
      }
    } catch (error) {
      console.error("Error fetching HSN codes:", error);
    }
  };
  const fetchSubCategories = async (categoryId) => {
    if (!categoryId) {
      setSubCatData([]);
      return;
    }
    try {
      const response = await $.getJSON(
        `${ApiURl}/getsubCategory.php?category_id=${categoryId}`
      );
      setSubCatData(response.data);
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };
  const handleSubCategoryChange = (e) => {
    const subCategoryId = e.target.value;
    setCurrentSubCategoryId(subCategoryId);
  };
  const handleHsnCodeChange = (e) => {
    setCurrentHsnCodeId(e.target.value); // Update HSN code selection
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file)); // Create and set preview URL
    }
  };
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };
  const handleSizeCheckboxChange = (e, size) => {
    if (e.target.checked) {
      setSelectedSizes([...selectedSizes, size]);
    } else {
      setSelectedSizes(selectedSizes.filter((s) => s !== size));
      const newSizePrices = { ...sizePrices };
      delete newSizePrices[size];
      setSizePrices(newSizePrices); // Remove price if size is unchecked

      // Remove the discount for the size if unchecked
      const newSizeDiscounts = { ...sizeDiscounts };
      delete newSizeDiscounts[size];
      setSizeDiscounts(newSizeDiscounts);
    }
  };
  const handlePriceChange = (e, size) => {
    const newPrice = e.target.value;
    if (!isNaN(newPrice) && newPrice >= 0) {
      setSizePrices((prevPrices) => ({
        ...prevPrices,
        [size]: parseFloat(newPrice), // Make sure price is stored as a number
      }));
    }
  };
  const handleAddDiscount = (size) => {
    const currentMinQuantity = minQuantities[size]; // Get the minQuantity for the specific size
    const currentDiscountPrice = discountPrices[size]; // Get the discountPrice for the specific size

    if (currentMinQuantity && currentDiscountPrice && size) {
      setDiscounts((prevDiscounts) => {
        // Check if the size already has existing discounts
        const existingDiscounts = prevDiscounts[size] || [];

        // Add a new discount object for the size
        const newDiscount = {
          minQuantity: currentMinQuantity,
          discountPrice: currentDiscountPrice,
        };

        // Add the new discount to the existing ones
        return {
          ...prevDiscounts,
          [size]: [...existingDiscounts, newDiscount], // Append new discount to the size
        };
      });

      // Reset the minQuantity and discountPrice input fields for the specific size
      setMinQuantities((prevState) => ({
        ...prevState,
        [size]: "", // Reset minQuantity for the selected size
      }));
      setDiscountPrices((prevState) => ({
        ...prevState,
        [size]: "", // Reset discountPrice for the selected size
      }));
    }
  };

  const handleDiscountChange = (e, size) => {
    const newDiscount = e.target.value;
    if (!isNaN(newDiscount) && newDiscount >= 0 && newDiscount <= 100) {
      setDiscountPrices((prevDiscounts) => ({
        ...prevDiscounts,
        [size]: parseFloat(newDiscount), // Store discount as a number for each size
      }));
    }
  };

  const handleMinQuantityChange = (e, size) => {
    const value = e.target.value;
    // Ensure that the value is a valid number and not empty
    if (!isNaN(value) && value >= 1) {
      setMinQuantities((prevState) => ({
        ...prevState,
        [size]: parseInt(value, 10), // Store the min_quantity as a number
      }));
    }
  };

  const handleRemoveDiscount = (size, index) => {
    setDiscounts((prevDiscounts) => {
      const newDiscounts = { ...prevDiscounts };
      
      // Remove specific discount from the size's discount array
      if (newDiscounts[size]) {
        newDiscounts[size] = newDiscounts[size].filter((_, i) => i !== index);
        
        // If no discounts remain for that size, remove the size key
        if (newDiscounts[size].length === 0) {
          delete newDiscounts[size];
        }
      }
      
      return newDiscounts;
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("productPrice", parseInt(productPrice)); // Default price
    formData.append("description", description);
    formData.append("brand", brand);
    formData.append("currentCategoryId", parseInt(currentCategoryId));
    formData.append("currentSubCategoryId", parseInt(currentSubCategoryId));
    formData.append("stock_quantity", stockQuantity);
    formData.append("hsn_code", currentHsnCodeId); // Add selected HSN code to the form
    formData.append("image", imageFile);

    // Add selected sizes and their prices
    selectedSizes.forEach((size) => {
      formData.append("sizes[]", size);
      formData.append(`price_${size}`, sizePrices[size] || 0); // Use sizePrices for the size prices

      // Include discount information for each size if available
      if (discounts[size]) {
        // Loop through each discount for this size and append them with indexes
        discounts[size].forEach((discount, index) => {
          formData.append(
            `discounts[${size}][${index}][min_quantity]`,
            discount.minQuantity
          );
          formData.append(
            `discounts[${size}][${index}][discounted_price]`,
            discount.discountPrice
          );
        });
      }
    });

    // Log the FormData to see what data is being sent
    for (let [key, value] of formData.entries()) {
      console.log(key + ": " + value);
    }

    $.ajax({
      url: `${ApiURl}/createProduct.php`,
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      success: function (response) {
        if (response.success) {
          alert("Product added successfully");
          setImageFile(null); // Reset the file input
          setImagePreview(null); // Clear image preview
        }
        console.log("Success:", response);
      },
      error: function (error) {
        console.error("Error:", error.responseText);
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 bg-gray-100 md:grid-cols-2 lg:grid-cols-3 gap-6 px-7">
          <section className="lg:col-span-2 bg-white">
            <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
              <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {" "}
                    Product Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="Type product name"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {" "}
                    Category
                  </label>
                  <select
                    id="category"
                    onChange={handleCategoryChange}
                    value={currentCategoryId}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  >
                    <option value="" hidden>
                      Select category
                    </option>
                    {catData.map((row) => (
                      <option key={row.id} value={row.id}>
                        {row.category_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="sub_category"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Sub-Category
                  </label>
                  <select
                    id="sub_category"
                    onChange={handleSubCategoryChange} // Update sub-category handler
                    value={currentSubCategoryId}
                    disabled={!currentCategoryId}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  >
                    <option value="" hidden>
                      {" "}
                      Select sub-category{" "}
                    </option>
                    {subCatData.map((row) => (
                      <option key={row.category_id} value={row.category_id}>
                        {row.category_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="hsn_code"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {" "}
                    HSN Code{" "}
                  </label>
                  <select
                    id="hsn_code"
                    value={currentHsnCodeId}
                    onChange={handleHsnCodeChange}
                    disabled={!currentCategoryId}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  >
                    <option value="" hidden>
                      {" "}
                      Select HSN Code{" "}
                    </option>
                    {hsnCodeData.map((hsn) => (
                      <option key={hsn.id} value={hsn.id}>
                        {hsn.hsn_code} - {hsn.description}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="brand"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {" "}
                    Brand{" "}
                  </label>
                  <input
                    type="text"
                    id="brand"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="Product brand"
                  />
                </div>
                <div>
                  <label
                    htmlFor="numericDescription"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Stock Quantity
                  </label>
                  <input
                    type="number"
                    id="numericDescription"
                    value={stockQuantity}
                    onChange={(e) => setStockQuantity(e.target.value)}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="Enter a number"
                    required
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {" "}
                    Description
                  </label>
                  <textarea
                    id="description"
                    rows="4"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="block p-2.5 w-full text-sm text-gray-900 border border-gray-300 rounded-lg"
                    placeholder="Type product description..."
                    required
                  ></textarea>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="size"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {" "}
                    Sizes
                  </label>
                  <div className="overflow-x-auto">
                    <table className="min-w-full table-auto">
                      <thead>
                        <tr>
                          <th className="px-4 py-2 text-left text-sm font-medium text-gray-900">
                            Select
                          </th>
                          <th className="px-4 py-2 text-left text-sm font-medium text-gray-900">
                            Size
                          </th>
                          <th className="px-4 py-2 text-left text-sm font-medium text-gray-900">
                            Price
                          </th>
                          <th className="px-4 py-2 text-left text-sm font-medium text-gray-900">
                            min_quantity
                          </th>
                          <th className="px-4 py-2 text-left text-sm font-medium text-gray-900">
                            Discount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sizeOptions.map((option) => (
                          <tr key={option.size}>
                            <td className="px-4 py-2">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleSizeCheckboxChange(e, option.size)
                                }
                                checked={selectedSizes.includes(option.size)}
                                className="h-4 w-4"
                              />
                            </td>
                            {/* <td className="px-4 py-2">{option.size}</td> */}
                            <td className="px-4 py-2">
                              <label
                                htmlFor="sizes_discount"
                                className="sr-only"
                              >
                                Size
                              </label>
                              <input
                                type="text"
                                id="sizes_discount"
                                value={option.size}
                                onChange={(e) =>
                                  handlePriceChange(e, option.size)
                                }
                                className="border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:outline-none"
                                placeholder="Enter Size"
                              />
                            </td>
                            <td className="px-4 py-2">
                              {selectedSizes.includes(option.size) && (
                                <>
                                  <label
                                    htmlFor={`price_${option.size}`}
                                    className="sr-only"
                                  >
                                    Price
                                  </label>
                                  <input
                                    type="number"
                                    id={`price_${option.size}`}
                                    placeholder="Enter Price"
                                    value={sizePrices[option.size] || ""}
                                    onChange={(e) =>
                                      handlePriceChange(e, option.size)
                                    }
                                    className="border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:outline-none"
                                    min="0"
                                    step="0.01"
                                  />
                                </>
                              )}
                            </td>

                            <td className="px-4 py-2">
                              {selectedSizes.includes(option.size) && (
                                <>
                                  <label
                                    htmlFor={`min_quantity_${option.size}`}
                                    className="sr-only"
                                  >
                                    Minimum Quantity
                                  </label>
                                  <input
                                    type="number"
                                    id={`min_quantity_${option.size}`}
                                    value={minQuantities[option.size] || ""}
                                    onChange={(e) =>
                                      handleMinQuantityChange(e, option.size)
                                    } // Ensure it triggers the correct function
                                    className="border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:outline-none"
                                    placeholder="Enter minimum quantity"
                                    min="1"
                                  />
                                </>
                              )}
                            </td>

                            <td className="px-4 py-2">
                              {selectedSizes.includes(option.size) && (
                                <>
                                  <label
                                    htmlFor={`discount_percentage_${option.size}`}
                                    className="sr-only"
                                  >
                                    Discount Percentage
                                  </label>
                                  <input
                                    type="number"
                                    id={`discount_percentage_${option.size}`}
                                    value={discountPrices[option.size] || ""} // Track discount for specific size
                                    onChange={(e) =>
                                      handleDiscountChange(e, option.size)
                                    } // Use specific handler
                                    className="border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:outline-none"
                                    placeholder="Enter discount rate"
                                    min="0"
                                    max="100"
                                    step="0.01"
                                  />
                                </>
                              )}
                            </td>

                            <td className="px-2 py-1">
                              {selectedSizes.includes(option.size) && (
                                <button
                                  type="button"
                                  onClick={() => handleAddDiscount(option.size)}
                                  className="px-4 py-2 bg-blue-600 text-white rounded-lg focus:outline-none hover:bg-blue-700"
                                >
                                  Add Discount
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mt-4 space-y-2">
                  {Object.entries(discounts).flatMap(([size, discountList]) =>
                    discountList.map((discount, index) => (
                      <div
                        key={`${size}-${index}`}
                        className="flex items-center"
                      >
                        <span className="mr-2">
                          Min Quantity: {discount.minQuantity}, Discount:{" "}
                          {discount.discountPrice}, Size: {size}
                        </span>
                        <button
                          type="button"
                          onClick={() => handleRemoveDiscount(size, index)} // Update to remove based on size and index
                          className="ml-2 text-red-500"
                        >
                          Remove
                        </button>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop{" "}
                  </p>
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF (Max size: 2MB){" "}
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleImageUpload}
                  accept="image/png, image/jpeg, image/gif"
                />
              </label>
              {imagePreview && (
                <div className="mt-4">
                  <img
                    src={imagePreview}
                    alt="Uploaded Preview"
                    className="w-full h-64 object-contain border rounded-lg"
                  />
                </div>
              )}
              <button
                type="submit"
                className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-blue-700 rounded-lg"
              >
                {" "}
                Add product
              </button>
            </div>
          </section>
        </div>
      </form>
    </>
  );
}
export default AddProduct;
