import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import imgLocation from "../controllers/imagePath";
import $ from "jquery";
import ApiURl from "../controllers/Api"; // Import API URL for wishlist handling
import { useNavigate } from "react-router-dom";

const ProductItems = ({ products }) => {
    const navigate = useNavigate();
  const [wishlistAdded, setWishlistAdded] = useState({}); // Track if product is in wishlist
  const [showPopup, setShowPopup] = useState(false);
  const [productList, setProductList] = useState(products); // Local product list to update on refresh

  // Handle Add to Wishlist action
  const handleAddToWishlist = async (product) => {
    try {
      const userId = localStorage.getItem("user_id"); // or wherever you're storing the user ID
      if (!userId) {
        alert("Please log in to add to wishlist.");
        return;
      }

      const productId = product.product_id;

      // Perform AJAX request to send the data as JSON
      const response = await $.ajax({
        url: `${ApiURl}/addWishlistItem.php`,
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify({
          user_id: userId, 
          product_id: productId, 
        }),
      });

      if (response.message === "Item added to wishlist successfully") {
        console.log("Item added to wishlist");
        setWishlistAdded((prevState) => ({
          ...prevState,
          [product.product_id]: true,
        }));
        setShowPopup(true); 
      } else {
        console.error("Error adding to wishlist:", response.message);
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };

  // // Fetch updated product list every second
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     fetchUpdatedProducts(); // Fetch the latest product data every second
  //   }, 1000);

  //   // Cleanup the interval when component unmounts
  //   return () => clearInterval(intervalId);
  // }, []); // Empty dependency array ensures this runs once when component mounts

  const fetchUpdatedProducts = async () => {
    try {
      const response = await $.ajax({
        url: `${ApiURl}/getUpdatedProducts.php`, // API endpoint to fetch updated products
        method: "GET",
      });
      setProductList(response.products); // Assuming 'response.products' contains the updated product data
    } catch (error) {
      console.error("Error fetching updated products:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup when the close button is clicked
  };

  return (


    <div  className="relative">
       <button
    onClick={() => navigate(-1)}
    className="px-4 py-2 bg-[#C77A2B] text-white rounded-full shadow-md hover:bg-[#b06924] transition flex items-center gap-2 mb-4"
  >
    {/* SVG for Back Arrow */}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="w-5 h-5"
    >
      <path d="M15 18l-6-6 6-6" />
    </svg>
    Back
  </button>


    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {productList.map((product) => (
        <div
          key={product.product_id}
          className="bg-gradient-to-r from-[#E5AA70] via-[#E5AA70] to-[#C77A2B] border rounded-lg overflow-hidden shadow-sm hover:shadow-lg transform hover:scale-105 transition-all duration-500 ease-in-out relative"
        >
          <Link to={`/ProductOverview/${product.product_id}`} className="relative">
            <img
              src={`${imgLocation}/${product.img_path}`}
              alt={product.name}
              className="w-full h-48 sm:h-64 object-cover transition-all duration-300 ease-in-out transform"
            />
            <div className="p-4">
              <h3 className="text-lg font-semibold text-gray-800">{product.name}</h3>

              <div className="flex items-center justify-between mt-4">
                <button className="bg-[#C77A2B] text-white px-4 py-2 rounded-md hover:bg-[9F5D1D] transition">
                  Buy Now
                </button>
              </div>
            </div>

            {/* Heart Icon for Wishlist at Bottom-Right */}
            <button
              onClick={() => handleAddToWishlist(product)}
              className={`absolute bottom-10 right-4 text-3xl transition-all duration-300 ease-in-out transform hover:scale-110 ${
                wishlistAdded[product.product_id] ? "text-red-500" : "text-gray-700"
              }`}
            >
              ❤️
            </button>

            {/* Popup/Modal when item is added to wishlist */}
            {showPopup && (
              <div className="popup-overlay">
                <div className="popup-content">
                  <h2>Item Added to Wishlist</h2>
                  <button onClick={handleClosePopup} className="close-button">
                    Close
                  </button>
                </div>
              </div>
            )}

            {/* Wishlist Text below Heart */}
            <div className="absolute bottom-2 right-4 text-center">
              <span
                className={`text-sm ${
                  wishlistAdded[product.product_id] ? "text-red-500" : "text-gray-700"
                }`}
              >
                Wishlist
              </span>
            </div>
          </Link>
        </div>
      ))}
    </div>
    </div>
  );
};

export default ProductItems;
