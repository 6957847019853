import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApiURl from "../controllers/Api";
import imgLocation from "../controllers/imagePath";
import $ from "jquery";
import CheckoutProductsView from "./checkoutProductsview";

function Cart() {
  const [cartId, setCartId] = useState(localStorage.getItem("cart_id"));
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );

  const [totalAmount, setTotalAmount] = useState(
    parseFloat(localStorage.getItem("totalAmount")) || 0
  );

  const [selectedItems, setSelectedItems] = useState([]); // Track selected items
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState("");
  const handleclickback = () => {
    navigate("/");
  };

  const ProductPrice = ({ product }) => {
    const [confettiTriggered, setConfettiTriggered] = useState(false); // Track confetti state

    // Trigger confetti logic
    const triggerConfetti = () => {
      if (window.confetti && !confettiTriggered) {
        window.confetti({
          particleCount: 5,
          spread: 30,
          origin: { x: 0.5, y: 0.5 },
        });
        setConfettiTriggered(true); // Mark that confetti has been triggered
      }
    };

    // Trigger confetti when price drops below original price
    useEffect(() => {
      if (
        product.discount_price &&
        product.discount_price < product.price &&
        !confettiTriggered
      ) {
        triggerConfetti(); // Trigger confetti animation only once
      }
    }, [product.discount_price, product.price, confettiTriggered]);
    // const interval = setInterval(ProductPrice, 1000);

    // return () => clearInterval(interval);
  };

  useEffect(() => {
    // This ensures the quantity starts as blank when the component mounts (including after a refresh)
    setQuantity("");
  }, []);

  const addToWishlist = async (productId) => {
    const userId = localStorage.getItem("user_id");
    try {
      const response = await fetch(`${ApiURl}/addWishlistItem.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId, product_id: productId }),
      });
      const data = await response.json();
      alert(data.message);
    } catch (error) {
      console.error("Error adding item to wishlist:", error);
      alert("Error adding item to wishlist");
    }
  };

  const handleProceedToCheckout = () => {
    const selectedCartItems = cart.filter((item) =>
      selectedItems.includes(item.cart_item_id)
    );

    // If no item is selected
    if (selectedCartItems.length === 0) {
      alert("Please select at least one item to proceed to checkout.");
      return;
    }

    // Proceed to the checkout page
    navigate("/cart/checkout", { state: { selectedCartItems } });
  };

  // Debounce function implementation
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // function CartComponent() {
  //   const [cart, setCart] = useState([]);
  //   const [totalAmount, setTotalAmount] = useState(0);

  // Handle quantity change
  const handleQuantityChange = async (cartItemId, quantity) => {
    // Reset quantity to blank after each refresh
    if (quantity === "" || isNaN(quantity) || quantity <= 0) {
      if (quantity === "") {
        // If the input is cleared, just keep the current state as blank
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.cart_item_id === cartItemId ? { ...item, quantity: "" } : item
          )
        );
      }
      return;
    }

    // Calculate the total here based on the original price (without discount)
    const updatedTotal =
      cart.find((item) => item.cart_item_id === cartItemId).price * quantity;

    try {
      const response = await fetch(`${ApiURl}/updateCartItem.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart_item_id: cartItemId,
          quantity,
          total: updatedTotal, // Pass the newly calculated total
        }),
      });

      const result = await response.json();

      if (result.success) {
        // Update the cart with the new quantity, discounted price, and new total
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.cart_item_id === cartItemId
              ? {
                  ...item,
                  quantity,
                  original_price: result.original_price, // Update original price
                  discounted_price: result.discounted_price, // Update discounted price
                  total: result.new_total, // Update total with the new total from the response
                }
              : item
          )
        );

        // Update the overall total and GST
        const updatedTotalAmount = cart.reduce((acc, item) => {
          if (item.cart_item_id === cartItemId) {
            return acc + result.new_total; // Use the updated total from response
          }
          return acc + item.discounted_price * item.quantity;
        }, 0);

        setTotalAmount(updatedTotalAmount);

        // Update local storage with the updated cart and total amount
        const updatedCart = cart.map((item) =>
          item.cart_item_id === cartItemId
            ? {
                ...item,
                quantity,
                original_price: result.original_price,
                discounted_price: result.discounted_price,
                total: result.new_total,
              }
            : item
        );
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        localStorage.setItem("totalAmount", updatedTotalAmount);
      } else {
        alert("Failed to update quantity");
      }
    } catch (error) {
      console.error("Error updating cart item:", error);
      alert("Error updating quantity");
    }
  };

  const debouncedHandleQuantityChange = useRef(
    debounce(handleQuantityChange, 5000)
  ).current;

  const handleInputChange = (e, cartItemId) => {
    const value = e.target.value;
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.cart_item_id === cartItemId ? { ...item, quantity: value } : item
      )
    );

    // Call the debounced function
    debouncedHandleQuantityChange(cartItemId, value);
  };

  const removeCartItem = async (cartItemId) => {
    try {
      const response = await fetch(`${ApiURl}/removeCartItem.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cart_item_id: cartItemId }),
      });
      if (response.ok) {
        const updatedCart = cart.filter(
          (item) => item.cart_item_id !== cartItemId
        );
        setCart(updatedCart);

        // Update totals and local storage
        const updatedTotalAmount = updatedCart.reduce(
          (acc, item) => acc + item.price * item.quantity,
          0
        );

        setTotalAmount(updatedTotalAmount);

        localStorage.setItem("cart", JSON.stringify(updatedCart));
        localStorage.setItem("totalAmount", updatedTotalAmount);

        alert("Item removed successfully");
      } else {
        console.error("Failed to remove item");
      }
    } catch (error) {
      console.error("Error removing item:", error);
    }
  };

  const handleSelectItem = async (cartItemId) => {
    const isSelected = selectedItems.includes(cartItemId);
    const newSelectedItems = isSelected
      ? selectedItems.filter((id) => id !== cartItemId)
      : [...selectedItems, cartItemId];

    // Update selectedItems state
    setSelectedItems(newSelectedItems);

    // Update selection in the database
    try {
      const response = await fetch(`${ApiURl}/updateCartSelection.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cart_item_id: cartItemId,
          selected: isSelected ? 0 : 1, // Toggle selection state (0 for deselected, 1 for selected)
        }),
      });
      const result = await response.json();
      if (!result.success) {
        console.error("Error updating selection in database:", result.message);
      }
    } catch (error) {
      console.error("Error updating item selection:", error);
    }
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await fetch(
          `${ApiURl}/getCartItems.php?cart_id=${cartId}`
        );
        const data = await response.json();
        console.log(data);
        if (data?.data) {
          setCart(data.data);
          const total = data.data.reduce(
            (acc, item) => acc + item.price * item.quantity,
            0
          );
          setTotalAmount(total);

          // Initialize selected items state
          const selectedItemsFromDb = data.data
            .filter((item) => item.selected === 1)
            .map((item) => item.cart_item_id);
          setSelectedItems(selectedItemsFromDb);

          // Store data in local storage
          localStorage.setItem("cart", JSON.stringify(data.data));
          localStorage.setItem("totalAmount", total);
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    };

    const interval = setInterval(fetchCartItems, 200);

    return () => clearInterval(interval);
    fetchCartItems();
  }, [cartId]);

  // Calculate selected items total and GST
  const selectedCartItems = cart.filter((item) =>
    selectedItems.includes(item.cart_item_id)
  );
  const selectedTotalAmount = selectedCartItems.reduce(
    (acc, item) => acc + item.total,
    0
  );
  useEffect(() => {
    // Dynamically load the confetti library
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/canvas-confetti@1.5.1/dist/confetti.browser.min.js";
    script.async = true;
    script.onload = () => {
      window.confetti = window.confetti || null;
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup the script when component unmounts
    };
  }, []);

  // // Trigger confetti logic
  // const triggerConfetti = () => {
  //   if (window.confetti) {
  //     window.confetti({
  //       particleCount: 100,
  //       spread: 70,
  //       origin: { x: 0.5, y: 0.5 },
  //     });
  //   }
  // };

  // const handleQuantityWithConfetti = (productId, newQuantity, total) => {
  //   if (newQuantity >= 50) {
  //     // triggerConfetti(); // Trigger confetti when quantity is 5 or more
  //   }
  //   handleQuantityChange(productId, newQuantity); // Update quantity
  // };

  return (
    <section className="bg-gray-100 py-8 md:py-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <button
    onClick={() => navigate(-2)}
    className="px-4 py-2 bg-[#C77A2B] text-white rounded-full shadow-md hover:bg-[#b06924] transition flex items-center gap-2 mb-4"
  >
    {/* SVG for Back Arrow */}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="w-5 h-5"
    >
      <path d="M15 18l-6-6 6-6" />
    </svg>
    Back
  </button>
        <h2 className="text-2xl font-bold text-gray-800 sm:text-3xl mb-6">
          Shopping Cart
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Table Section */}
          <div className="col-span-2 space-y-6">
            {/* Mobile Layout: List of products as cards */}
            <div className="block md:hidden">
              {cart.map((product) => (
                <div
                  key={product.cart_item_id}
                  className={`flex flex-col bg-white p-5 rounded-lg shadow-md mb-5 ${
                    selectedItems.includes(product.cart_item_id)
                      ? "bg-blue-50"
                      : "bg-white"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(product.cart_item_id)}
                      onChange={() => handleSelectItem(product.cart_item_id)}
                      className="mr-3 h-5 w-5"
                    />
                    <img
                      className="h-28 w-28 rounded-lg object-cover"
                      src={`${imgLocation}/${product.img_path}`}
                      alt={product.name}
                    />
                  </div>
                  <div className="mt-3">
                    <p className="font-bold text-gray-800 text-lg">
                      {product.name}
                    </p>
                    <div className="mt-3">
                      <p className="text-sm font-semibold text-gray-600">
                        Size: {product.size || "N/A"}
                      </p>
                      <div className="flex items-center gap-2">
                        {product.discount_price &&
                        product.discount_price < product.price ? (
                          <>
                            <p className="text-sm font-semibold text-gray-600 line-through">
                              &#x20b9;{product.price.toFixed(2)}
                            </p>
                            <p className="text-xl font-bold text-green-600">
                              &#x20b9;{product.discount_price.toFixed(2)}
                            </p>
                          </>
                        ) : (
                          <p className="text-xl font-bold text-gray-600">
                            &#x20b9;{product.price.toFixed(2)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-5 mt-5">
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() =>
                            handleQuantityChange(
                              product.cart_item_id,
                              product.quantity - 1
                            )
                          }
                          disabled={product.quantity <= 1}
                          className="bg-blue-200 text-blue-800 px-3 py-2 rounded-md hover:bg-blue-300 text-sm font-semibold"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          min="1"
                          value={product.quantity || 0} // Show 0 if quantity is empty
                          onChange={(e) =>
                            handleQuantityChange(
                              product.cart_item_id,
                              e.target.value
                            )
                          }
                          className="w-16 border border-gray-300 rounded px-3 py-2 text-center font-medium text-gray-800"
                        />

                        <button
                          onClick={() =>
                            handleQuantityChange(
                              product.cart_item_id,
                              product.quantity + 1
                            )
                          }
                          className="bg-blue-200 text-blue-800 px-3 py-2 rounded-md hover:bg-blue-300 text-sm font-semibold"
                        >
                          +
                        </button>
                      </div>
                      <span className="text-sm font-semibold text-gray-600">
                        Total: &#x20b9;
                        {product.total && !isNaN(product.total)
                          ? product.total.toFixed(2)
                          : "0.00"}
                      </span>
                    </div>
                    <div className="flex gap-3 mt-4">
                      <button
                        onClick={() => addToWishlist(product.product_id)}
                        className="text-blue-500 text-sm font-semibold hover:underline"
                      >
                        Wishlist
                      </button>
                      <span>|</span>
                      <button
                        onClick={() => removeCartItem(product.cart_item_id)}
                        className="text-red-500 text-sm font-semibold hover:underline"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Desktop Layout: Table */}
            <div className="hidden md:block">
              <table className="w-full text-left border-collapse border border-gray-200 bg-white rounded-lg shadow">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="border border-gray-200 px-4 py-3 text-sm font-medium text-gray-700">
                      Select
                    </th>
                    <th className="border border-gray-200 px-4 py-3 text-sm font-medium text-gray-700">
                      Product
                    </th>
                    <th className="border border-gray-200 px-4 py-3 text-sm font-medium text-gray-700">
                      Size
                    </th>
                    <th className="border border-gray-200 px-4 py-3 text-sm font-medium text-gray-700">
                      Price
                    </th>
                    <th className="border border-gray-200 px-4 py-3 text-sm font-medium text-gray-700">
                      Quantity
                    </th>
                    <th className="border border-gray-200 px-4 py-3 text-sm font-medium text-gray-700">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((product) => (
                    <tr
                      key={product.cart_item_id}
                      className={`border-b border-gray-200 hover:bg-gray-50 ${
                        selectedItems.includes(product.cart_item_id)
                          ? "bg-blue-50"
                          : "bg-white"
                      }`}
                    >
                      <td className="border border-gray-200 px-4 py-3 text-sm">
                        <input
                          type="checkbox"
                          checked={selectedItems.includes(product.cart_item_id)}
                          onChange={() =>
                            handleSelectItem(product.cart_item_id)
                          }
                          className="mr-2"
                        />
                      </td>
                      <td className="border border-gray-200 px-4 py-3 text-sm">
                        <div className="flex items-center gap-4">
                          <img
                            className="h-16 w-16 rounded-lg object-cover"
                            src={`${imgLocation}/${product.img_path}`}
                            alt={product.name}
                          />
                          <div>
                            <p className="font-medium text-gray-800 truncate">
                              {product.name}
                            </p>
                            <div className="flex gap-2 mt-2">
                              <button
                                onClick={() =>
                                  addToWishlist(product.product_id)
                                }
                                className="text-blue-500 text-sm hover:underline"
                              >
                                Wishlist
                              </button>
                              <span>|</span>
                              <button
                                onClick={() =>
                                  removeCartItem(product.cart_item_id)
                                }
                                className="text-red-500 text-sm hover:underline"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="border border-gray-200 px-4 py-3 text-sm text-gray-700">
                        {product.size || "N/A"}
                      </td>
                      <td className="border border-gray-200 px-4 py-3 text-sm text-gray-700">
                        <div className="flex items-center gap-2">
                          {product.discount_price &&
                          product.discount_price < product.price ? (
                            <>
                              <p className="text-sm font-semibold text-gray-600 line-through">
                                &#x20b9;{product.price.toFixed(2)}
                              </p>
                              <p className="text-xl font-bold text-green-600">
                                &#x20b9;{product.discount_price.toFixed(2)}
                              </p>
                              <ProductPrice product={product} />
                            </>
                          ) : (
                            <p className="text-xl font-bold text-gray-600">
                              &#x20b9;{product.price.toFixed(2)}
                            </p>
                          )}
                        </div>
                      </td>

                      <td className="flex items-center space-x-2">
                        <button
                          onClick={() =>
                            handleQuantityChange(
                              product.cart_item_id,
                              product.quantity - 1
                            )
                          }
                          disabled={product.quantity <= 1}
                          className="bg-[#F2C194] text-blue-800 px-1 sm:px-2 py-1 rounded-md hover:bg-[#E5AA70] text-xs sm:text-sm"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          min="1"
                          value={product.quantity}
                          onChange={(e) =>
                            handleQuantityChange(
                              product.cart_item_id,
                              e.target.value
                            )
                          }
                          className="w-20 border border-gray-300 rounded px-2 py-1 text-center"
                        />
                        <button
                          onClick={() =>
                            handleQuantityChange(
                              product.cart_item_id,
                              product.quantity + 1
                            )
                          }
                          className="bg-[#F2C194] text-blue-800 px-1 sm:px-2 py-1 rounded-md hover:bg-[#E5AA70] text-xs sm:text-sm"
                        >
                          +
                        </button>
                      </td>
                      <td className="border border-gray-200 px-4 py-3 text-sm">
                        &#x20b9;{product.total.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Summary Section */}
          <div className="lg:ml-8 mt-6 lg:mt-0">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Cart Summary
              </h3>

              {/* Responsive Table */}
              {selectedCartItems.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="table-auto w-full text-sm text-left text-gray-700">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="px-4 py-2">Selected Items</th>
                        <th className="px-4 py-2">Quantity</th>
                        <th className="px-4 py-2">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCartItems.map((item) => (
                        <tr key={item.cart_item_id} className="border-b">
                          <td className="px-4 py-2 text-gray-800">
                            {item.name}
                          </td>
                          <td className="px-4 py-2 text-gray-800">
                            {item.quantity}
                          </td>
                          <td className="px-4 py-2 text-gray-800">
                            &#x20b9;{item.total.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-sm text-gray-500">No items selected.</p>
              )}

              {/* Total Amount */}
              <div className="mt-6 flex justify-between text-sm text-gray-700 font-semibold">
                <span>Total Amount</span>
                <span>&#x20b9;{selectedTotalAmount.toFixed(2)}</span>
              </div>

              {/* Buttons */}
              <div className="flex justify-center gap-4 mt-6">
                <button
                  onClick={handleclickback}
                  className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                >
                  Continue Shopping
                </button>
                <button
                  onClick={handleProceedToCheckout}
                  className="bg-[#C77A2B] hover:bg-[#C77A2B] text-white px-4 py-2 rounded-md"
                >
                  Placed order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cart;
