import React, { useState, useEffect } from "react";
import ApiURl from "../../controllers/Api";

function ManageDiscounts() {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [minQuantity, setMinQuantity] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");
  const [formVisible, setFormVisible] = useState(false);
  const [showAllDiscounts, setShowAllDiscounts] = useState({});
  const [selectedSize, setSelectedSize] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${ApiURl}/getDiscounts.php`);
        const data = await response.json();
        console.log(data);
        setProducts(data.products || []);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();

    const intervalId = setInterval(fetchProducts, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedProduct || !minQuantity || !discountedPrice || !endDate || !selectedSize) {
        setMessage("All fields are required.");
        return;
    }

    const product = products.find((p) => p.product_id === selectedProduct);
    const sizes = product ? product.sizes : [];

    if (sizes.length === 0) {
        setMessage("No sizes available for this product.");
        return;
    }

    try {
        const response = await fetch(`${ApiURl}/update_discount.php`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                product_id: selectedProduct,
                size: selectedSize,
                min_quantity: minQuantity,
                discounted_price: discountedPrice,
                end_date: endDate,
            }),
        });

        const text = await response.text(); // Read response as text
        console.log("Response Text:", text); // Log the response

        try {
            const data = JSON.parse(text); // Try parsing as JSON
            if (data.status === "success") {
                setMessage("Discount added/updated successfully!");
                setMinQuantity("");
                setDiscountedPrice("");
                setEndDate("");
            } else {
                setMessage(data.message || "Error adding/updating discount.");
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            setMessage("An error occurred while processing the discount.");
        }
    } catch (error) {
        console.error("Error:", error);
        setMessage("An error occurred while processing the discount.");
    }
};

  const handleRemoveDiscount = async (productId, productPriceId, minQuantity, size) => {
    try {
      const response = await fetch(`${ApiURl}/remove_discount.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          product_id: productId,
          product_price_id: productPriceId,
          min_quantity: minQuantity,
          size: size,
        }),
      });

      const data = await response.json();
      console.log("Response Data:", data);

      if (data.status === "success") {
        setMessage("Discount removed successfully!");
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.product_id === productId
              ? {
                  ...product,
                  discounted_price: product.discounted_price.filter(
                    (price, index) => index !== productPriceId
                  ),
                  min_quantity: product.min_quantity.filter(
                    (quantity, index) => index !== productPriceId
                  ),
                }
              : product
          )
        );
      } else {
        setMessage(data.message || "Error removing discount.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred while removing the discount.");
    }
  };

  const handleProductClick = (productId) => {
    if (selectedProduct === productId) {
      setFormVisible(!formVisible);
    } else {
      setSelectedProduct(productId);
      setFormVisible(true);
    }
  };

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  const toggleShowAllDiscounts = (productId) => {
    setShowAllDiscounts((prevState) => ({
      ...prevState,
      [productId]: !prevState[productId],
    }));
  };

  return (
    <div className="bg-gray-100 h-screen w-full flex items-center justify-center flex-col">
      <section className="bg-white w-full max-w-full lg:w-3/4 xl:w-2/3 2xl:w-1/2 border border-gray-900 rounded-lg shadow-lg p-6 overflow-hidden flex flex-col flex-grow">
        <div className="py-8 px-4 mx-auto max-w-2xl flex-shrink-0">
          {/* Fixed Top Bar */}
          <h2 className="text-xl font-semibold text-gray-900 mb-4 sticky top-0 bg-white z-10">Manage Discounts</h2>

          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              {products.length > 0 ? (
                <div className="space-y-6">
                  <div className="hidden sm:block">
                    <div className="overflow-x-auto max-h-[300px] overflow-y-auto">
                      <table className="w-full table-auto border-collapse">
                        <thead>
                          <tr>
                            <th className="py-2 px-4 text-left">Product</th>
                            <th className="py-2 px-4 text-left">Min Quantity</th>
                            <th className="py-2 px-4 text-left">Discounted Price</th>
                            <th className="py-2 px-4 text-left">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.map((product) => (
                            <React.Fragment key={product.product_id}>
                              <tr className="cursor-pointer">
                                <td className="py-2 px-4">{product.name}</td>
                                <td className="py-2 px-4">
                                  {product.discounted_price.length > 0
                                    ? product.min_quantity[0]
                                    : "N/A"}
                                </td>
                                <td className="py-2 px-4">
                                  {product.discounted_price.length > 0
                                    ? `₹${product.discounted_price[0]}`
                                    : "No Discount"}
                                </td>
                                <td className="py-2 px-4">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleProductClick(product.product_id);
                                    }}
                                    className="px-4 py-1 rounded-md bg-blue-500 text-white hover:bg-opacity-80 text-[10px]"
                                  >
                                    {selectedProduct === product.product_id && formVisible
                                      ? "Close"
                                      : "Select"}
                                  </button>

                                  {product.discounted_price.length > 0 && !showAllDiscounts[product.product_id] && (
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveDiscount(
                                          product.product_id,
                                          0,
                                          product.min_quantity[0],
                                          // product.sizes[0]
                                        );
                                      }}
                                      className="px-2 py-1 rounded-md bg-red-500 text-white hover:bg-opacity-80 text-[10px] sm:hidden"
                                    >
                                      ✖ Remove
                                    </button>
                                  )}

                                  {product.discounted_price.length > 1 && (
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleShowAllDiscounts(product.product_id);
                                      }}
                                      className="px-3 py-1 rounded-md bg-green-500 text-white hover:bg-opacity-80 text-[10px]"
                                    >
                                      {showAllDiscounts[product.product_id]
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </td>
                              </tr>

                              {showAllDiscounts[product.product_id] && (
                                <tr>
                                  <td colSpan="4">
                                    {product.discounted_price.map((price, index) => (
                                      <div key={index} className="py-2 px-4">
                                        <p>Min Quantity: {product.min_quantity[index]}</p>
                                        <p>Discounted Price: ₹{price}</p>
                                        <div>
{/*                                           
                                            {product.sizes.map((size, idx) => (
                                              <option key={idx} value={size}>
                                                {size}
                                              </option>
                                            ))} */}
                                         
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleRemoveDiscount(
                                                product.product_id,
                                                index,
                                                product.min_quantity[index],
                                                selectedSize
                                              );
                                            }}
                                            className="text-red-500 ml-2 sm:hidden"
                                          >
                                            ✖ Remove Discount
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Mobile Layout */}
                  <div className="sm:hidden">
                    {products.map((product) => (
                      <div key={product.product_id} className="mb-6 p-4 border border-gray-300 rounded-lg">
                        <div className="flex flex-col space-y-3">
                          <div className="text-lg font-semibold">{product.name}</div>
                          <div>Min Quantity: {product.discounted_price.length > 0 ? product.min_quantity[0] : "N/A"}</div>
                          <div>Discounted Price: {product.discounted_price.length > 0 ? `₹${product.discounted_price[0]}` : "No Discount"}</div>
                        </div>
                        <div className="mt-4 flex justify-between">
                          <button
                            onClick={() => handleProductClick(product.product_id)}
                            className="px-3 py-1 rounded-md bg-blue-500 text-white text-sm"
                          >
                            {selectedProduct === product.product_id && formVisible
                              ? "Close"
                              : "Select"}
                          </button>

                          {product.discounted_price.length > 0 && !showAllDiscounts[product.product_id] && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveDiscount(
                                  product.product_id,
                                  0,
                                  product.min_quantity[0],
                                  // product.sizes[0]
                                );
                              }}
                              className="px-2 py-1 rounded-md bg-red-500 text-white text-sm sm:hidden"
                            >
                              ✖ Remove
                            </button>
                          )}

                          {product.discounted_price.length > 1 && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleShowAllDiscounts(product.product_id);
                              }}
                              className="px-3 py-1 rounded-md bg-green-500 text-white text-sm"
                            >
                              {showAllDiscounts[product.product_id]
                                ? "Show Less"
                                : "Show More"}
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>No products found.</p>
              )}
            </div>

            {selectedProduct && formVisible && (
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-900">
                  Set Discount for Selected Product
                </label>
                <div className="mt-4 flex flex-col space-y-4 sm:grid sm:grid-cols-2 sm:gap-6">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Select Size
                    </label>
                    <select
                      value={selectedSize}
                      onChange={handleSizeChange}
                      required
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    >
                      <option value="">Select Size</option>
                      {products
                        .find((product) => product.product_id === selectedProduct)
                        ?.sizes.map((size, idx) => (
                          <option key={idx} value={size}>
                            {size}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Minimum Quantity
                    </label>
                    <input
                      name="minQuantity"
                      value={minQuantity}
                      onChange={(e) => setMinQuantity(e.target.value)}
                      type="number"
                      required
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Discounted Price
                    </label>
                    <input
                      name="discountedPrice"
                      value={discountedPrice}
                      onChange={(e) => setDiscountedPrice(e.target.value)}
                      type="number"
                      required
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      End Date
                    </label>
                    <input
                      name="endDate"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      type="date"
                      required
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-4 px-6 py-2 w-full sm:w-auto bg-blue-600 text-white rounded-lg"
                >
                  Apply Discount
                </button>
              </div>
            )}
          </form>

          {message && (
            <div className="mt-4 text-center text-sm text-red-500">{message}</div>
          )}
        </div>
      </section>
    </div>
  );
}

export default ManageDiscounts;
